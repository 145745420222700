import { Component, Input } from '@angular/core';
import { UiFields } from 'src/app/constants/ui-config';

@Component({
  selector: 'app-demand-schedule-detail',
  templateUrl: './demand-schedule-detail.component.html',
  styleUrls: ['./demand-schedule-detail.component.scss']
})
export class DemandScheduleDetailComponent {

  @Input() uiFieldsMap: Array<any> = [];
  @Input() demandSchedule: UiFields = {};
  @Input() enableEdit: boolean = false;


}
