import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { KycObject } from "../../organisms/ekyc-section/ekyc.constants";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { SubscriptionReviewService } from "../../services/customer-group/subscription-review/subscription-review.service";
import { CommonReportsDownloadService } from "../../services/common-reports-download.service";
import { getProperty } from "src/app/utils/app.utils";

@Component({
  selector: "app-ekyc-details-section",
  templateUrl: "./ekyc-details-section.component.html",
  styleUrls: ["./ekyc-details-section.component.scss"],
})
export class EkycDetailsSectionComponent implements OnInit, OnChanges {
  @Input() kycData: KycObject = {};
  @Input() entityType: string = null;
  @Input() loanId: number = null;

  @Output() getKycData: EventEmitter<any> = new EventEmitter<any>();

  isReject: boolean = false;
  rejectRemarks: string = null;
  reviewSatus: string = null;

  constructor(
    private subscriptionReviewService: SubscriptionReviewService,
    private reportService: CommonReportsDownloadService
  ) {}


  ngOnInit(): void {
   this.reviewSatus = getProperty(this.kycData, "verificationStatus.value", ""); 
  }

  ngOnChanges(): void {
    this.reviewSatus = getProperty(this.kycData, "verificationStatus.value", ""); 
  }

  reject(event: MatCheckboxChange): void {
    const checked: boolean = event.checked;
    this.isReject = checked;
    if (!checked) {
      this.rejectRemarks = "";
    }
  }

  updateDocReviewStatus(): void {
    const payload: Array<any> = [];
    const reviewStatus: string = this.isReject ? "REJECT" : "ACCEPT";
    const docId: number = getProperty(this.kycData, "id.value", null);
    const docObject: any = {
      id: docId,
      reviewStatus: reviewStatus,
      entityType: this.entityType,
    };
    if (this.isReject) {
      docObject["rejectReason"] = this.rejectRemarks;
    }
    payload.push(docObject);

    const isCoApplicant: boolean = this.entityType === "CO_APPLICANT";

    this.subscriptionReviewService
      .updateKYCReviewStatus(this.loanId, payload, isCoApplicant)
      .subscribe(() => {
        this.getKycData.emit();
      });
  }

  openEkycReport(): void {
    const routerLink: string = "ekyc-report";
    this.reportService.openReport(this.kycData, routerLink);
  }

  isPending(): boolean {
    return this.reviewSatus.toLowerCase() === "pending";
  }


  getReviewStatus(): string {
    const status = this.reviewSatus.toLowerCase();
  
    switch (status) {
      case 'accept':
        return 'Accepted';
  
      case 'rejected':
        return 'Rejected';
    }
    return status;
  }
  
  isFailure(): boolean {
    return this.reviewSatus.toLowerCase() === "failure";
  }

}
