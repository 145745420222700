<div style="margin: 5vh 0 3vh 0">
  <!-- Sub-section Title  -->
  <div class="sub-heading-container">
    <div class="document-type-title">Borrower’s beneficiary check</div>
    <div class="button-container">
      <button
        mat-raised-button
        type="button"
        class="btn btn-primary"
        data-dismiss="modal"
        (click)="retryCheck()"
        [disabled]="beneficiaryData?.status?.value === 'VERIFIED' || !isAgreementRecieved || !editSections"
        [hidden]="enableEdit"
      >
        Retry Beneficiary Check
      </button>
      <button
        mat-raised-button
        type="button"
        class="btn btn-primary"
        data-dismiss="modal"
        [hidden]="enableEdit"
        [disabled]="!canEdit || !editSections || !isAgreementRecieved"
        (click)="toggleEditDetails()"
      >
        <span class="fa fa-pencil"></span>&nbsp;<span jhiTranslate="entity.action.edit">Edit</span>
      </button>
      <button
        mat-raised-button
        type="button"
        class="btn-secondary"
        data-dismiss="modal"
        [hidden]="!enableEdit"
        (click)="cancelEditDetails()"
      >
        <span jhiTranslate="entity.action.cancel">Cancel</span>
      </button>
      <button
        mat-raised-button
        type="button"
        class="btn-primary"
        data-dismiss="modal"
        (click)="saveDetails()"
        [hidden]="!enableEdit"
      >
        <span jhiTranslate="entity.action.save">Save</span>
      </button>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankAccountNum !== null && beneficiaryData.bankAccountNum?.editable"
        >
          <mat-label for="bankAccountNum">Beneficiary Account Number</mat-label>
          <input
            id="bankAccountNum"
            matInput
            type="text"
            name="bankAccountNum"
            [(ngModel)]="beneficiaryData.bankAccountNum.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankAccountNum?.editable && enableEdit">
          <label class="form-control-label" for="bankAccountNumDisplay">Beneficiary Account Number</label>
          <jhi-null-replace id="bankAccountNumDisplay" [value]="beneficiaryData?.bankAccountNum?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.accountHolderName !== null && beneficiaryData.accountHolderName?.editable"
        >
          <mat-label for="accountHolderName">Beneficiary Name</mat-label>
          <input
            id="accountHolderName"
            matInput
            type="text"
            name="accountHolderName"
            [(ngModel)]="beneficiaryData.accountHolderName.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.accountHolderName?.editable && enableEdit">
          <label class="form-control-label" for="accountHolderNameDisplay">Beneficiary Name</label>
          <jhi-null-replace id="accountHolderNameDisplay" [value]="beneficiaryData?.accountHolderName?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.ifscCode !== null && beneficiaryData.ifscCode?.editable"
        >
          <mat-label for="ifscCode">Beneficiary IFSC Code</mat-label>
          <input
            id="ifscCode"
            matInput
            type="text"
            name="ifscCode"
            [(ngModel)]="beneficiaryData.ifscCode.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.ifscCode?.editable && enableEdit">
          <label class="form-control-label" for="ifscCodeDisplay">Beneficiary IFSC Code</label>
          <jhi-null-replace id="ifscCodeDisplay" [value]="beneficiaryData?.ifscCode?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <button
          mat-raised-button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          [disabled]="!enableEdit"
          (click)="searchIfsc()"
        >
          Search IFSC
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.mobileNumber !== null && beneficiaryData.mobileNumber?.editable"
        >
          <mat-label for="mobileNumber">Beneficiary Mobile Number</mat-label>
          <input
            id="mobileNumber"
            matInput
            type="text"
            name="mobileNumber"
            [(ngModel)]="beneficiaryData.mobileNumber.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.mobileNumber?.editable && enableEdit">
          <label class="form-control-label" for="mobileNumberDisplay">Beneficiary Mobile Number</label>
          <jhi-null-replace id="mobileNumberDisplay" [value]="beneficiaryData?.mobileNumber?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.borrowerType !== null && beneficiaryData.borrowerType?.editable"
        >
          <mat-label for="borrowerType">Borrower Type</mat-label>
          <input
            id="borrowerType"
            matInput
            type="text"
            name="borrowerType"
            [(ngModel)]="beneficiaryData.borrowerType.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.borrowerType?.editable && enableEdit">
          <label class="form-control-label" for="borrowerTypeDisplay">Borrower Type</label>
          <jhi-null-replace id="borrowerTypeDisplay" [value]="beneficiaryData?.borrowerType?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankAccountType !== null && beneficiaryData.bankAccountType?.editable"
        >
          <mat-label for="bankAccountType">Account Type</mat-label>
          <input
            id="bankAccountType"
            matInput
            type="text"
            name="bankAccountType"
            [(ngModel)]="beneficiaryData.bankAccountType.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankAccountType?.editable && enableEdit">
          <label class="form-control-label" for="bankAccountTypeDisplay">Account Type</label>
          <jhi-null-replace id="bankAccountTypeDisplay" [value]="beneficiaryData?.bankAccountType?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankName !== null && beneficiaryData.bankName?.editable"
        >
          <mat-label for="bankName">Bank Name</mat-label>
          <input
            id="bankName"
            matInput
            type="text"
            name="bankName"
            [(ngModel)]="beneficiaryData.bankName.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankName?.editable && enableEdit">
          <label class="form-control-label" for="bankNameDisplay">Bank Name</label>
          <jhi-null-replace id="bankNameDisplay" [value]="beneficiaryData?.bankName?.value"></jhi-null-replace>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankBranch !== null && beneficiaryData.bankBranch?.editable"
        >
          <mat-label for="bankBranch">Bank Branch</mat-label>
          <input
            id="bankBranch"
            matInput
            type="text"
            name="bankBranch"
            [(ngModel)]="beneficiaryData.bankBranch.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankBranch?.editable && enableEdit">
          <label class="form-control-label" for="bankBranchDisplay">Bank Branch</label>
          <jhi-null-replace id="bankBranchDisplay" [value]="beneficiaryData?.bankBranch?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.pennyDropPerformedAt !== null && beneficiaryData.pennyDropPerformedAt?.editable"
        >
          <mat-label for="pennyDropPerformedAt">Beneficiary Check Date and Time</mat-label>
          <input
            id="pennyDropPerformedAt"
            matInput
            type="text"
            name="pennyDropPerformedAt"
            [(ngModel)]="beneficiaryData.pennyDropPerformedAt.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.pennyDropPerformedAt?.editable && enableEdit">
          <label class="form-control-label" for="pennyDropPerformedAtDisplay">Beneficiary Check Date and Time</label>
          <jhi-null-replace id="pennyDropPerformedAtDisplay" [value]="beneficiaryData?.pennyDropPerformedAt?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.accountHolderNameInBank !== null && beneficiaryData.accountHolderNameInBank?.editable"
        >
          <mat-label for="accountHolderNameInBank">Beneficiary Name as per Bank</mat-label>
          <input
            id="accountHolderNameInBank"
            matInput
            type="text"
            name="accountHoldernameInBank"
            [(ngModel)]="beneficiaryData.accountHolderNameInBank.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.accountHolderNameInBank?.editable && enableEdit">
          <label class="form-control-label" for="accountHolderNameInBankDisplay">Beneficiary Name as per Bank</label>
          <jhi-null-replace id="accountHolderNameInBankDisplay" [value]="beneficiaryData?.accountHolderNameInBank?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <label class="form-control-label" for="statusDisplay">Status</label>
        <div id="statusDisplay" [ngClass]="statusMapper[beneficiaryData?.status?.value]?.class">
          {{ statusMapper[beneficiaryData?.status?.value]?.message || "--" }}
          <img *ngIf="statusMapper[beneficiaryData?.status?.value]?.message" [src]="statusMapper[beneficiaryData?.status?.value]?.icon" [alt]="statusMapper[beneficiaryData?.status?.value]?.message"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <label class="form-control-label" for="messageDisplay">Message</label>
        <jhi-null-replace id="messageDisplay" [value]="beneficiaryData?.providerDescription?.value"></jhi-null-replace>
      </div>
    </div>
  </div>

  <!-- Sub-section Title  -->
  <div class="sub-heading-container">
    <div class="document-type-title">Bank Branch Details</div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankName !== null && beneficiaryData.bankName?.editable"
        >
          <mat-label for="bankBranchDetailsName">Bank Name</mat-label>
          <input
            id="bankBranchDetailsName"
            matInput
            type="text"
            name="bankName"
            [(ngModel)]="beneficiaryData.bankName.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankName?.editable && enableEdit">
          <label class="form-control-label" for="bankBranchDetailsNameDisplay">Bank Name</label>
          <jhi-null-replace id="bankBranchDetailsNameDisplay" [value]="beneficiaryData?.bankName?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.micrCode !== null && beneficiaryData.micrCode?.editable"
        >
          <mat-label for="micrCode">MICR Code</mat-label>
          <input
            id="micrCode"
            matInput
            type="text"
            name="micrCode"
            [(ngModel)]="beneficiaryData.micrCode.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.micrCode?.editable && enableEdit">
          <label class="form-control-label" for="micrCodeDisplay">MICR Code</label>
          <jhi-null-replace id="micrCodeDisplay" [value]="beneficiaryData?.micrCode?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankCode !== null && beneficiaryData.bankCode?.editable"
        >
          <mat-label for="bankCode">Bank Code</mat-label>
          <input
            id="bankCode"
            matInput
            type="text"
            name="bankCode"
            [(ngModel)]="beneficiaryData.bankCode.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankCode?.editable && enableEdit">
          <label class="form-control-label" for="bankCodeDisplay">Bank Code</label>
          <jhi-null-replace id="bankCodeDisplay" [value]="beneficiaryData?.bankCode?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankBranch !== null && beneficiaryData.bankBranch?.editable"
        >
          <mat-label for="bankBranchDetailsBranch">Bank Branch</mat-label>
          <input
            id="bankBranchDetailsBranch"
            matInput
            type="text"
            name="bankBranch"
            [(ngModel)]="beneficiaryData.bankBranch.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankBranch?.editable && enableEdit">
          <label class="form-control-label" for="bankBranchDetailsBranchDisplay">Bank Branch</label>
          <jhi-null-replace id="bankBranchDetailsBranchDisplay" [value]="beneficiaryData?.bankBranch?.value"></jhi-null-replace>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankAddress !== null && beneficiaryData.bankAddress?.editable"
        >
          <mat-label for="bankAddress">Bank Address</mat-label>
          <textarea
            id="bankAddress"
            matInput
            [disabled]="!enableEdit"
            name="bankAddress"
            [(ngModel)]="beneficiaryData.bankAddress.value"
            style="line-height: unset; resize: none"
            rows="3"
          ></textarea>
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankAddress?.editable && enableEdit">
          <label class="form-control-label" for="bankAddressDisplay">Bank Address</label>
          <jhi-null-replace id="bankAddressDisplay" [value]="beneficiaryData?.bankAddress?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankContact !== null && beneficiaryData.bankContact?.editable"
        >
          <mat-label for="bankContact">Bank Contact</mat-label>
          <input
            id="bankContact"
            matInput
            type="text"
            name="bankContact"
            [(ngModel)]="beneficiaryData.bankContact.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankContact?.editable && enableEdit">
          <label class="form-control-label" for="bankContactDisplay">Bank Contact</label>
          <jhi-null-replace id="bankContactDisplay" [value]="beneficiaryData?.bankContact?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankCity !== null && beneficiaryData.bankCity?.editable"
        >
          <mat-label for="bankCity">Bank City</mat-label>
          <input
            id="bankCity"
            matInput
            type="text"
            name="bankCity"
            [(ngModel)]="beneficiaryData.bankCity.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankCity?.editable && enableEdit">
          <label class="form-control-label" for="bankCityDisplay">Bank City</label>
          <jhi-null-replace id="bankCityDisplay" [value]="beneficiaryData?.bankCity?.value"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankDistrict !== null && beneficiaryData.bankDistrict?.editable"
        >
          <mat-label for="bankDistrict">Bank District</mat-label>
          <input
            id="bankDistrict"
            matInput
            type="text"
            name="bankDistrict"
            [(ngModel)]="beneficiaryData.bankDistrict.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankDistrict?.editable && enableEdit">
          <label class="form-control-label" for="bankDistrictDisplay">Bank District</label>
          <jhi-null-replace id="bankDistrictDisplay" [value]="beneficiaryData?.bankDistrict?.value"></jhi-null-replace>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <mat-form-field
          appearance="outline"
          [hidden]="!enableEdit"
          *ngIf="beneficiaryData?.bankState !== null && beneficiaryData.bankState?.editable"
        >
          <mat-label for="bankState">Bank State</mat-label>
          <input
            id="bankState"
            matInput
            type="text"
            name="bankState"
            [(ngModel)]="beneficiaryData.bankState.value"
          />
        </mat-form-field>
        <div [hidden]="beneficiaryData.bankState?.editable && enableEdit">
          <label class="form-control-label" for="bankStateDisplay">Bank State</label>
          <jhi-null-replace id="bankStateDisplay" [value]="beneficiaryData?.bankState?.value"></jhi-null-replace>
        </div>
      </div>
    </div>
  </div>
</div>
