<mat-accordion>
  <mat-expansion-panel
    [expanded]="panelOpenState"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <div class="expansion-panel-title-container">
        <div class="section-title">Basic Customer Info</div>
        <app-section-edit-action
          (onCancelHandler)="cancel($event)"
          (onSaveHandler)="save($event)"
          [panelOpenState]="panelOpenState"
          [isEditing]="enableEdit"
          (onEditHandler)="toggleEditDetails($event)"
        ></app-section-edit-action>
      </div>
    </mat-expansion-panel-header>

    <div class="card-title">
      <form name="customerInfoForm" novalidate #customerInfoForm="ngForm">
        <div class="row row-spacing">
          <div class="form-group col-3">
            <div [hidden]="!enableEdit">
              <mat-form-field appearance="outline">
                <mat-label for="partnerCustomerId"
                  >Partner Customer Id</mat-label
                >
                <input
                  matInput
                  name="partnerCustomerId"
                  id="partnerCustomerId"
                  [(ngModel)]="customer.partnerCustomerId"
                  required="required"
                />
              </mat-form-field>
            </div>
            <div [hidden]="enableEdit">
              <label class="form-control-label" for="partnerCustomerId"
                >Partner Customer Id</label
              >
              <jhi-null-replace
                [value]="customer.partnerCustomerId"
              ></jhi-null-replace>
            </div>
          </div>
          <div class="form-group col-3">
            <div [hidden]="!enableEdit">
              <mat-form-field appearance="outline">
                <mat-label for="gender">Gender</mat-label>
                <mat-select
                  [(ngModel)]="customer.gender"
                  name="gender"
                  id="gender"
                >
                  <mat-option
                    *ngFor="let gender of genders"
                    [value]="gender.code"
                  >
                    {{ gender.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div [hidden]="enableEdit">
              <label class="form-control-label" for="gender">Gender</label>
              <jhi-null-replace [value]="customer.gender"></jhi-null-replace>
            </div>
          </div>
        </div>
        <hr class="section-hr" />
        <h5 style="font-weight: bold" class="section-sub-heading">
          Applicant Details as per Aadhaar card
        </h5>
        <div>
          <div class="row row-spacing">
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="applicantName">Applicant Name</mat-label>
                  <input
                    matInput
                    name="applicantName"
                    id="applicantName"
                    [(ngModel)]="customer.name"
                    required="required"
                  />
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="applicantName"
                  >Applicant Name</label
                >
                <jhi-null-replace [value]="customer.name"></jhi-null-replace>
              </div>
            </div>
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="fatherName">Father Name</mat-label>
                  <input
                    matInput
                    name="fatherName"
                    id="fatherName"
                    [(ngModel)]="customer.fatherName"
                  />
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="fatherName"
                  >Father Name</label
                >
                <jhi-null-replace
                  [value]="customer.fatherName"
                ></jhi-null-replace>
              </div>
            </div>
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="spouseName">Spouse Name</mat-label>
                  <input
                    matInput
                    name="spouseName"
                    id="spouseName"
                    [(ngModel)]="customer.spouseName"
                  />
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="spouseName"
                  >Spouse Name</label
                >
                <jhi-null-replace
                  [value]="customer.spouseName"
                ></jhi-null-replace>
              </div>
            </div>
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="gender2">Gender</mat-label>
                  <mat-select
                    [(ngModel)]="customer.gender"
                    name="gender2"
                    id="gender2"
                  >
                    <mat-option
                      *ngFor="let gender of genders"
                      [value]="gender.code"
                    >
                      {{ gender.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="gender2">Gender</label>
                <jhi-null-replace [value]="customer.gender"></jhi-null-replace>
              </div>
            </div>
          </div>
          <div class="row row-spacing">
            <div class="col-3">
              <div [hidden]="!enableEdit" class="input-group">
                <mat-form-field style="width: 80%" appearance="outline">
                  <mat-label for="dateOfBirth">Date of Birth</mat-label>
                  <input
                    matInput
                    [matDatepicker]="dateOfBirth"
                    placeholder="Date of Birth"
                    [max]="maxDate"
                    [min]="minDate"
                    (dateChange)="updateDate($event)"
                    [value]="dateDisplay"
                    id="dateOfBirth"
                    name="dateOfBirth"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dateOfBirth"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #dateOfBirth></mat-datepicker>
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="dateOfBirth"
                  >Date Of Birth</label
                >
                <jhi-null-replace
                  [value]="customer.dateOfBirth"
                  inputType="date"
                ></jhi-null-replace>
              </div>
            </div>
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <div class="aadhaar-container">
                  <mat-form-field appearance="outline">
                    <mat-label for="aadhaarNumber">Aadhaar Number</mat-label>
                    <input
                      matInput
                      name="aadhaarNumber"
                      id="aadhaarNumber"
                      [(ngModel)]="customer.maskedAadhaar"
                      minlength="12"
                      maxlength="12"
                      type="number"
                      pattern="^[0-9]{12}$"
                      [errorStateMatcher]="errorMatcher"
                      required="required"
                    />
                    <mat-error>{{ errorConstants.aadhaarNumber }}</mat-error>
                  </mat-form-field>
                  <jhi-aadhaar-idfy
                    *ngIf="isAadhaarVerificationNeeded"
                    [customer]="customer"
                    [loanApplicationDTO]="loanApplicationDTO"
                  ></jhi-aadhaar-idfy>
                </div>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="aadhaarNumber"
                  >Aadhaar Number</label
                >
                <div class="aadhaar-container">
                  <jhi-null-replace
                    [value]="customer.maskedAadhaar"
                  ></jhi-null-replace>
                  <jhi-aadhaar-idfy
                    *ngIf="isAadhaarVerificationNeeded"
                    [customer]="customer"
                    [loanApplicationDTO]="loanApplicationDTO"
                  ></jhi-aadhaar-idfy>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="panNumber">Pan Number</mat-label>
                  <input
                    matInput
                    name="panNumber"
                    id="panNumber"
                    [(ngModel)]="customer.panNumber"
                    [disabled]="!enableEdit"
                    pattern="^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$"
                    minlength="10"
                    maxlength="10"
                    [errorStateMatcher]="errorMatcher"
                  />
                  <mat-error>{{ errorConstants.panNumber }}</mat-error>
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="panNumber"
                  >Pan Number</label
                >
                <jhi-null-replace
                  [value]="customer.panNumber"
                ></jhi-null-replace>
              </div>
            </div>
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="maritalStatus">Marital Status</mat-label>
                  <mat-select
                    name="maritalStatus"
                    [(ngModel)]="customer.maritalStatus"
                    id="maritalStatus"
                  >
                    <mat-option
                      *ngFor="let maritalStatus of maritalStatuses"
                      [value]="maritalStatus.code"
                    >
                      {{ maritalStatus.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div [hidden]="enableEdit">
                <label class="form-control-label" for="maritalStatus"
                  >Marital Status</label
                >
                <jhi-null-replace
                  [value]="customer.maritalStatus"
                ></jhi-null-replace>
              </div>
            </div>
          </div>
          <div class="row row-spacing">
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="primaryMobileNumber">Mobile Number</mat-label>
                  <input
                    matInput
                    name="primaryMobileNumber"
                    id="primaryMobileNumber"
                    pattern="^\d{0,10}$"
                    type="number"
                    minlength="10"
                    maxlength="10"
                    [(ngModel)]="customer.primaryMobileNumber"
                    required
                  />
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="primaryMobileNumber"
                  >Mobile Number</label
                >
                <jhi-null-replace
                  [value]="customer.primaryMobileNumber"
                ></jhi-null-replace>
              </div>
            </div>
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="poiType">KYC POI Document type</mat-label>
                  <input
                    [disabled]="true"
                    matInput
                    name="poiType"
                    id="poiType"
                    [value]="docType?.poi || '--'"
                    required
                  />
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="poiType"
                  >KYC POI Document type</label
                >
                <jhi-null-replace [value]="docType?.poi"></jhi-null-replace>
              </div>
            </div>
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="poiNumber">KYC POI Proof number</mat-label>
                  <input
                    [disabled]="true"
                    matInput
                    name="poiNumber"
                    id="poiNumber"
                    [value]="docId?.poi || '--'"
                    required
                  />
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="poiNumber"
                  >KYC POI Proof number</label
                >
                <jhi-null-replace [value]="docId?.poi"></jhi-null-replace>
              </div>
            </div>
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="poiType">KYC POA Document type</mat-label>
                  <input
                    [disabled]="true"
                    matInput
                    name="poiType"
                    id="poiType"
                    [value]="docType?.poa || '--'"
                    required
                  />
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="poiType"
                  >KYC POA Document type</label
                >
                <jhi-null-replace [value]="docType?.poa"></jhi-null-replace>
              </div>
            </div>
          </div>
          <div class="row row-spacing">
            <div class="col-3">
              <div [hidden]="!enableEdit">
                <mat-form-field appearance="outline">
                  <mat-label for="poiNumber">KYC POA Proof number</mat-label>
                  <input
                    [disabled]="true"
                    matInput
                    name="poaNumber"
                    id="poaNumber"
                    [value]="docId?.poa || '--'"
                    required
                  />
                </mat-form-field>
              </div>
              <div [hidden]="enableEdit">
                <label class="form-control-label" for="poaNumber"
                  >KYC POA Proof number</label
                >
                <jhi-null-replace [value]="docId?.poa"></jhi-null-replace>
              </div>
            </div>
          </div>
        </div>
        <hr class="section-hr" />
        <h5 class="section-title">Address Details</h5>
      </form>
      <div *ngFor="let address of addressList; let i = index">
        <div
          style="margin-top: 5ex"
          *ngIf="
            address.addressType === 'BUSINESS' ||
            address.addressType === 'CURRENT' ||
            address.addressType === 'PERMANENT' ||
            address.addressType === 'BUSINESS_OPERATING'
          "
        >
          <jhi-basic-customer-address
            [address]="address"
            [index]="i"
            (reloadAfterSave)="onSuccess($event)"
          ></jhi-basic-customer-address>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
