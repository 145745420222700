<mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <div class="expansion-panel-title-container">
                <div class="section-title">Customer Demand Schedule</div>
                <button [hidden]="editDetails" [disabled]="!editSections || !hasAuthority" mat-raised-button *ngIf="panelOpenState" type="button"
                    class="btn btn-primary" data-dismiss="modal" (click)="toggleEditDetails($event)">
                    <span class="fa fa-pencil"></span>&nbsp;<span jhiTranslate="entity.action.edit">Edit</span>
                </button>
                <div class="confirm-buttons" [hidden]="!editDetails">
                    <button mat-raised-button type="button" class="btn-secondary" data-dismiss="modal"
                        (click)="cancel($event)">
                        <span jhiTranslate="entity.action.cancel">Cancel</span>
                    </button>
                    <button mat-raised-button type="button" class="btn-primary" data-dismiss="modal"
                        (click)="save($event)">
                        <span jhiTranslate="entity.action.save">Save</span>
                    </button>
                </div>
            </div>
        </mat-expansion-panel-header>
        <div *ngIf="uiFieldsMap.length > 0">
            <app-dynamic-render-component [uiFields]="demandSchedule" [uiFieldsMap]="uiFieldsMap" [loanId]="loanId"
                [editSections]="editSections" [enableEdit]="editDetails" [hideEditAction]="true" [uiApiKey]="'DEMAND_SCHEDULE'">
            </app-dynamic-render-component>
        </div>
        <hr class="section-hr">
        <div *ngIf="paymentSchedule.length > 0">
            <h3 class="section-sub-heading">Payment Schedule</h3>
            <div class="table-container">
                <table>
                    <caption></caption>
                    <thead>
                        <tr>
                            <th class="table-heading"><span>Due Date</span></th>
                            <th class="table-heading"><span>Opening balance</span></th>
                            <th class="table-heading"><span>EMI</span></th>
                            <th class="table-heading"><span>Principle</span></th>
                            <th class="table-heading"><span>Interest</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let schedule of paymentSchedule">
                            <td>
                                <jhi-null-replace [value]="schedule?.emiDate">
                                </jhi-null-replace>
                            </td>
                            <td>
                                <jhi-null-replace
                                    [value]="schedule?.outstandingBalance | dynamicDataTransform : 'currency'">
                                </jhi-null-replace>
                            </td>
                            <td>
                                <jhi-null-replace [value]="schedule?.emiAmount | dynamicDataTransform : 'currency'">
                                </jhi-null-replace>
                            </td>
                            <td>
                                <jhi-null-replace [value]="schedule?.principle | dynamicDataTransform : 'currency'">
                                </jhi-null-replace>
                            </td>
                            <td>
                                <jhi-null-replace
                                    [value]="schedule?.interestAmount | dynamicDataTransform : 'currency'">
                                </jhi-null-replace>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>