import { Component, Input } from "@angular/core";

@Component({
  selector: "app-input-field",
  templateUrl: "./input-field.component.html"
})
export class InputFieldComponent {
  @Input() dataObj: any = {};
  @Input() editable: boolean;
  @Input() label : string = "";
  @Input() isRequired: boolean = false;
  @Input() errors: any = {};
  @Input() inputLength :number ;
  
}
