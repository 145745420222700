export interface KycDetailsForLoan {
  id?: number;
  version?: number;
  customerId?: number;
  providerId?: number;
  providerName?: string;
  fileId?: number;
  documentName?: string;
  purpose?: string;
  verificationStatus?: string;
  message?: string | null;
  idNo?: string;
  maskedIdNo?: string | null;
  documentType?: string;
  customerFileMappingId?: number | null;
  sortOrder?: number | null;
  expiryDate?: string | null;
  groupId?: string;
  isDefault?: boolean | null;
  entityId?: number;
  entityType?: string;
}


export const VERIFICATION_TYPE = {
  "CO_APPLICANT": "coApplicant",
  "GUARANTOR": "guarantor",
  "NOMINEE": "nominee"
}