import { Component, Input } from '@angular/core';
import { Customer } from "../../../models/customer/customer.model";
import { KcreditLoanService } from "../../kcredit-loan.service";
import { KCreditLoanApplication } from "../../../report/kcredit-loan-application.model";

@Component({
    selector: 'jhi-aadhaar-idfy',
    templateUrl: './aadhaar-idfy.component.html',
    styleUrls: [
        '../../kcredit-loan.css','./aadhaar-idfy.component.scss'
    ]
})
export class AadhaarIdfyComponent {
    @Input() customer: Customer;
    @Input() loanApplicationDTO: KCreditLoanApplication;

    constructor(
        private kcreditLoanService: KcreditLoanService
      ) {

      }

  verifyAadhaar() {
    this.kcreditLoanService
      .verifyAadhaar(this.loanApplicationDTO).subscribe(
             (res) => this.successfulVerification(res),
             (res) => this.onSaveError(res)
      );
  }

  successfulVerification(res){
    this.loanApplicationDTO = res;
    if(this.loanApplicationDTO.aadhaarVerificationStatus === 'IN_PROGRESS'){
        this.kcreditLoanService
              .checkAadhaarVerificationStatus(this.loanApplicationDTO)
              .subscribe(
                (res) => this.successfulVerificationCheckForInProgressAadhaarIdfy(res),
                (res) => this.onSaveError(res)
              );
    }
  }

  successfulVerificationCheckForInProgressAadhaarIdfy(res){
    this.loanApplicationDTO = res;
  }

  private onSaveError(error) {
    try {
      error = error.json();
    } catch (exception) {
      error.message = error.text();
    }
  }


}
