import { Route } from "@angular/router";
import { IgKaleidofinHomeModalComponent } from "./kaleidofin-home.component";


export const HOME_ROUTE: Route = {
  path: "",
  component: IgKaleidofinHomeModalComponent,
  data: {
    authorities: [],
    pageTitle: "Kaleidofin"
  }
};
