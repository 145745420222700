<div>
  <ng-container *ngFor="let document of documents">
    <div class="col-md-12" style="height: 2ex"></div>
    <div class="col-md-12">
      <label
        class="form-control-label col-md-11 no-padding"
        style="color: #b0b6bf"
        htmlFor="label-1"
        >{{document.documentName}}
        <span class="pull-right" *ngIf="document?.uploadedDate"
          >Uploaded Date: {{document.uploadedDate | date }}</span
        ></label
      >

      <div *ngIf="verifyDocType(document,['jpg','jpeg','png'])">
        <div class="col-md-12 row">
          <div class="text-right">
            <button
              [disabled]="disableBasedOnStatus(document) || disableEdit"
              mat-raised-button
              class="btn-primary"
              data-dismiss="modal"
              (click)="editImage(document, 'CROP')"
            >
              <span class="fa fa-pencil"></span>&nbsp;<span
                jhiTranslate="entity.action.edit"
                >Edit</span
              >
            </button>
            &nbsp;&nbsp;
          </div>
        </div>
        <div class="col-md-12">&nbsp;</div>
        <img
          class="d-inline-block nach-image"
          *ngIf="document.image"
          [src]="domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + document.image)" alt="Nach"
        />
        <div class="col-md-12">&nbsp;</div>
      </div>

      <div *ngIf="verifyDocType(document,['pdf'])">
        <div class="review-edit-button" style="justify-content: flex-end">
          <button
            mat-raised-button
            type="button"
            class="btn-primary"
            data-dismiss="modal"
            (click)="openPdf(document.image)"
          >
            <span class="fa fa-folder-open"></span>&nbsp;<span>Open</span>
          </button>
        </div>
        <div class="col-md-12">&nbsp;</div>
      </div>

      <div class="form-group col-md-4">
        <section class="checkbox-section">
          <mat-checkbox
            [disabled]="disableBasedOnStatus(document)"
            type="checkbox"
            name="loan_agreement_reject"
            [(ngModel)]="document.rejectStatus"
          >
            Reject</mat-checkbox
          >
        </section>
      </div>
      <div class="form-group col-md-6" *ngIf="document.rejectStatus">
        <mat-form-field appearance="fill">
          <input
            [disabled]="disableBasedOnStatus(document)"
            matInput
            placeholder="Reject Reason"
            [(ngModel)]="document.reviewRemarks"
          />
        </mat-form-field>
        <label
          *ngIf="handleAndDisplayError(document,'reviewRemarks')"
          class="text-danger col-md-12"
          htmlFor="label-1"
        >
          Please choose a reason for rejecting the image.</label
        >
      </div>
      <div class="col-md-12"></div>
      <div class="text-right">
        <button
          mat-raised-button
          class="btn-warning"
          [disabled]="disableEdit || disableBasedOnStatus(document) || (document.rejectStatus && handleAndDisplayError(document,'reviewRemarks'))"
          data-dismiss="modal"
          (click)="updateDocument(document)"
        >
          <span *ngIf="document.rejectStatus">Reject</span>
          <span *ngIf="!document.rejectStatus">Accept</span>
        </button>
      </div>
      <div
        class="col-md-12"
        *ngIf="(document.reviewStatus && (document.reviewStatus === 'ACCEPT' || document.reviewStatus === 'REJECT'))"
      >
        <label class="form-control-label"
        htmlFor="label-1"
          ><span
            >{{document.reviewStatus === 'ACCEPT' ? 'Accepted' :
            'Rejected'}}</span
          ></label
        >
      </div>
    </div>
  </ng-container>
  <span *ngIf="!documents.length">--</span>
</div>
