import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { getProperty } from "src/app/utils/app.utils";

@Component({
  selector: "app-loan-stage-display",
  templateUrl: "./loan-stage-display.component.html",
  styleUrls: ["./loan-stage-display.component.scss"],
})
export class LoanStageDisplayComponent implements OnChanges {
  @Input() data: any = [];
  @Input() selectedLoanStageIndex: number = null;
  @Output() clickHandler: EventEmitter<any> = new EventEmitter<any>();
  selectedIndex: number = 0;

  onApplicationStateSelection(status, index) {
    this.selectedIndex = index;
    this.clickHandler.emit({ status, index });
  }
  ngOnChanges(changes: SimpleChanges): void {
    const loanStageIndex = getProperty(changes,'selectedLoanStageIndex',{});
    if(getProperty(loanStageIndex,'currentValue',0) !== getProperty(loanStageIndex,'previousValue',0) ){
      this.selectedIndex = 0;
    }
  }
}
