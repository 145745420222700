<div class="document-subtype-container">
  <div>Document Type {{ subtype ? " - " + subtype : "" }}</div>
  <div class="document-action-button-container" *ngIf="hideRejectAllSection()">
    <button
      mat-raised-button
      class="btn-secondary"
      [hidden]="!checkForTags() || isApplicationAssigned"
      (click)="tagAllDocuments()"
      [disabled]="!disableEditAccess"
    >
      Tag All
    </button>
    <mat-checkbox
      class="example-margin"
      [checked]="rejectAll"
      (change)="updateRejectAll($event.checked)"
      [disabled]="!disableEditAccess || checkForTags() || !isApplicationAssigned"
      >Reject All</mat-checkbox
    >
    <button
      mat-raised-button
      class="btn-primary"
      [disabled]="!disableEditAccess || checkForTags() || validateRejectAll() || !isApplicationAssigned"
      (click)="updateDocument()"
    >
      {{ rejectAll ? "Reject All" : "Accept All" }}
    </button>
  </div>
</div>
<div [ngClass]="['doc-container', 'align-start']">
  <div class="document-container">
    <div class="item item-container" *ngFor="let doc of documents">
      <div
        *ngIf="doc?.fileId || doc?.documentFileId"
        class="document-item-container"
      >
        <app-doc-title-container
          [doc]="doc"
          [tagDocumentFrom]="tagDocumentFrom"
          [documentCategory]="documentCategory"
          (editImage)="editImage(doc, 'CROP')"
          [hideEditButtons]="checkAndDisableActions(doc)"
          [isApplicationAssigned]="isApplicationAssigned"
        ></app-doc-title-container>
        <br />
        <div class="image-container" *ngIf="doc?.image">
          <img
            *ngIf="!isPdf(doc)"
            class="d-inline-block nach-image fill-image"
            [src]="doc?.image"
            [alt]="doc?.documentName"
          />
          <img
            *ngIf="isPdf(doc)"
            class="d-inline-block nach-image fill-image"
            src="assets/images/common/pdf-icon.svg"
            [alt]="doc?.documentName"
          />
        </div>
        <br />
        <div class="document-name-with-size">
          {{ doc?.documentName }} &nbsp; &#x2022; &nbsp;
          <span [class]="doc?.docSizeClass">{{ doc?.size }}</span>
        </div>
        <br />
        <div class="document-subtype-container">
          <mat-checkbox
            class="example-margin"
            [(ngModel)]="doc.rejectStatus"
            [hidden]="checkAndDisableActions(doc) || !isApplicationAssigned"
            [disabled]="!disableEditAccess || checkForTags() || !isApplicationAssigned"
            >Reject
          </mat-checkbox>
          <button
            mat-raised-button
            class="btn-primary"
            (click)="updateDocument(doc)"
            [hidden]="checkAndDisableActions(doc) || !isApplicationAssigned"
            [disabled]="
              !disableEditAccess || doc?.rejectStatus || checkForTags() || !isApplicationAssigned
            "
          >
            Accept
          </button>
        </div>
        <div class="document-subtype-container">
          <div *ngIf="statusDisplay(doc)">
            <label class="form-control-label" for="status"
              ><span>{{ statusDisplayText(doc) }} </span></label
            >
            <br />
            <label
              for="verificationStatus"
              *ngIf="
                (doc?.verificationStatus === 'REJECT' ||
                  doc?.verificationStatus === 'Rejected' ||
                  doc?.reviewStatus === 'REJECT') &&
                (doc?.reviewRemarks || doc?.rejectReason || doc?.message)
              "
              ><span
                >Reason -
                {{ doc?.reviewRemarks || doc?.rejectReason || doc?.message }}
              </span></label
            >
          </div>
          <div class="reject-reason-container" *ngIf="validateReasonInput(doc)">
            <mat-form-field>
              <input
                required
                matInput
                placeholder="Reject Reason"
                [(ngModel)]="doc.reviewRemarks"
                style="width: 100%"
                [disabled]="checkAndDisableActions(doc) || checkForTags() || !isApplicationAssigned"
              />
              <mat-error
                *ngIf="
                  doc?.reviewRemarks === null ||
                  (doc?.reviewRemarks && doc?.reviewRemarks.length < 1)
                "
              >
                Please enter a reason for rejecting the image.
              </mat-error>
            </mat-form-field>
            <button
              mat-raised-button
              class="btn-primary"
              (click)="updateDocument(doc)"
              [disabled]="
                !(
                  !checkAndDisableActions(doc) &&
                  doc?.reviewRemarks &&
                  doc?.reviewRemarks.length > 0
                ) || checkForTags() || !isApplicationAssigned
              "
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="enableKycVerification">
    <app-kyc-results-table
      [idNo]="idNo"
      [type]="subtype"
      [loanId]="loanId"
      [entityId]="doc?.entityId || entityId"
      [entityType]="entityType"
      [purpose]="purpose"
    ></app-kyc-results-table>
  </div>
</div>
