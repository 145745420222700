import { Component, Input, OnInit } from "@angular/core";
import { KycDetailsForLoan } from "../../models/kyc-details.model";
import { CustomerService } from "../../services/customer/customer.service";
import { get } from "lodash";

@Component({
  selector: "app-kyc-details-accordion",
  templateUrl: "./kyc-details-accordion.component.html",
})
export class KycDetailsAccordionComponent implements OnInit {
  @Input() partnerId: number;
  @Input() title: string = "";
  @Input() entityType: string = "";
  @Input() kycDocumentList: Array<KycDetailsForLoan> =
    new Array<KycDetailsForLoan>();
  @Input() loanId: number;

  poiDocs: Array<KycDetailsForLoan> = new Array<KycDetailsForLoan>();
  poaDocs: Array<KycDetailsForLoan> = new Array<KycDetailsForLoan>();

  constructor(private customerService: CustomerService) {}

  ngOnInit(): void {
    this.poiDocs = this.customerService.getKycDocuments(
      this.kycDocumentList,
      "POI",
      this.entityType
    );
    this.poaDocs = this.customerService.getKycDocuments(
      this.kycDocumentList,
      "POA",
      this.entityType
    );
  }

  checkSectionVisibility(type) {
    switch (type) {
      case "poa":
        return get(this.poaDocs, "length", 0) > 0;
      case "poi":
        return get(this.poiDocs, "length", 0) > 0;
      case "docs":
        return (
          get(this.poaDocs, "length", 0) > 0 ||
          get(this.poiDocs, "length", 0) > 0
        );
      default:
        return false;
    }
  }
}
