<div *ngIf="deviations.length > 0">
    <div class="section-sub-heading">Deviations</div>
    <div *ngIf="checkedDeviations.length > 0"> <span>{{ checkedDeviations.length }} Deviations Selected</span> <button
            mat-button [disabled]="!authority.approve" class="bulk-approve"
            (click)="handleDeviationDecision(checkedDeviations,'Approved')"><img
                src="assets/images/common/button-approve.svg" alt="Approve"><span>Approve</span></button><button
            mat-button [disabled]="!authority.approve" class="bulk-reject"
            (click)="handleDeviationDecision(checkedDeviations,'Declined')"><img
                src="assets/images/common/button-decline.svg" alt="Decline"><span>Decline</span></button>
    </div>
    <table>
        <caption></caption>
        <thead class="header-container">
            <tr>
                <th class="table-header" *ngFor="let column of tableData" [ngSwitch]="column?.type">
                    <ng-container *ngSwitchDefault>
                        <div class="table-header-text">{{ column?.label }} </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'checkbox'">
                        <div class="checkbox-container">
                            <mat-checkbox class="checkbox" [disabled]="!authority.approve" [disableRipple]="true"
                                (change)="checkAllDeviations()"></mat-checkbox>
                        </div>
                    </ng-container>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="currentDeviations.length > 0">
            <ng-container *ngFor="let deviation of currentDeviations">
                <ng-container *ngFor="let nameAndValue of deviation['deviationTypeAndValue']; let i = index">
                    <tr>
                        <td *ngIf="i === 0" [attr.rowspan]="deviation['deviationTypeAndValue'].length">
                            <div class="checkbox-container">
                                <mat-checkbox
                                    [checked]="checkAll && deviation['approveDeviations']?.[i]?.approveDeviation === 'Pending' && deviation['approveDeviations']?.[i]?.enableDeviationApproval"
                                    (change)="selectDeviation(deviation, $event)"
                                    [disabled]="!authority.approve || deviation['approveDeviations']?.[i]?.approveDeviation !== 'Pending' || !deviation['approveDeviations']?.[i]?.enableDeviationApproval">
                                </mat-checkbox>
                            </div>
                        </td>
                        <td *ngIf="i === 0" [attr.rowspan]="deviation['deviationTypeAndValue'].length">
                            <div class="table-column-text">
                                {{ deviation['ruleDescription'] }}
                            </div>
                        </td>
                        <td>
                            <div class="table-column-text">
                                {{ nameAndValue.name }}
                                <img id="infoIcon" *ngIf="deviation.name" src="assets/images/common/info-tooltip.svg"
                                    [alt]="deviation.name" [matTooltip]="deviation.featureDescription || ''"
                                    matTooltipPosition="above" />
                            </div>
                        </td>
                        <td>
                            <div class="table-column-text">
                                {{ nameAndValue.value }}
                            </div>
                        </td>
                        <td *ngIf="i === 0" [attr.rowspan]="deviation['deviationTypeAndValue'].length">
                            <div *ngIf="deviation['approveDeviations']?.[i]">
                                <ng-container
                                    *ngIf="deviation['approveDeviations']?.[i]?.approveDeviation === 'Pending'">
                                    <button mat-flat-button
                                        [disabled]="!authority.approve || !deviation['approveDeviations']?.[i]?.enableDeviationApproval"
                                        class="approve-button"
                                        (click)="handleDeviationDecision([deviation], 'Approved')">
                                        <span class="approve-text">
                                            Yes
                                        </span>
                                    </button>
                                    <button mat-flat-button
                                        [disabled]="!authority.approve || !deviation['approveDeviations']?.[i]?.enableDeviationApproval"
                                        class="reject-button" (click)="handleDeviationDecision([deviation],'Declined')">
                                        <span class="reject-text">
                                            No
                                        </span>
                                    </button>
                                </ng-container>
                                <div *ngIf="deviation['approveDeviations']?.[i]?.approveDeviation !== 'Pending'">
                                    <span [ngClass]="deviation['approveDeviations']?.[i]?.approveDeviation"> {{
                                        deviation['approveDeviations']?.[i]?.approveDeviation }} </span>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="i === 0" [attr.rowspan]="deviation['deviationTypeAndValue'].length">
                            <textarea *ngIf="deviation['approveDeviations']?.[i]?.approveDeviation === 'Pending'"
                                matInput
                                [disabled]="deviation['approveDeviations']?.[i]?.approveDeviation !== 'Pending' || !deviation['approveDeviations']?.[i]?.enableDeviationApproval"
                                placeholder="Write your remarks" cols="5" rows="5"
                                [(ngModel)]="deviation['approveDeviations'][i].remarks"></textarea>
                            <div *ngIf="deviation['approveDeviations']?.[i]?.approveDeviation !== 'Pending'">
                                {{ deviation['approveDeviations'][i]?.remarks }}
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
    </table>
    <div class="button-container" *ngIf="deviations.length > 5 && currentDeviations.length <= 5"> <button
            mat-raised-button class="view-all-button" (click)="showMoreDeviations()">
            <span class="view-all-text">View All</span>
        </button>
    </div>
</div>