<form name="guarantorForm" novalidate #guarantorForm="ngForm">
  <div style="margin-top: 5ex">
    <div class="col-12 row">
      <div class="col-9">
        <h5 class="section-sub-heading">Guarantor {{ index + 1 }}</h5>
      </div>

      <div class="col-3">
        <app-section-edit-action
          (onCancelHandler)="cancelEdit()"
          (onSaveHandler)="save(guarantor)"
          [panelOpenState]="true"
          [isEditing]="editGuarantor"
          (onEditHandler)="enableEdit()"
        ></app-section-edit-action>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-3">
        <ig-input
          [inputField]="guarantorForm.controls.name"
          [inputErrors]="guarantorForm.controls.name?.errors"
        >
          <label
            jhiTranslate="kCredit.loanObligators.name"
            class="form-control-label"
            for="name"
            >Name</label
          >
          <div [hidden]="!editGuarantor">
            <mat-form-field appearance="outline">
              <input
                matInput
                [disabled]="!editGuarantor"
                name="guarantorName"
                [(ngModel)]="guarantor.name"
              />
            </mat-form-field>
          </div>
        </ig-input>
        <div [hidden]="editGuarantor">
          <jhi-null-replace [value]="guarantor.name"></jhi-null-replace>
        </div>
      </div>
      <div class="col-3">
        <ig-input
          [inputField]="guarantorForm.controls.relationship"
          [inputErrors]="guarantorForm.controls.relationship?.errors"
        >
          <label
            jhiTranslate="kCredit.loanObligators.relationship"
            class="form-control-label"
            for="Relationship"
            >Relationship</label
          >
          <div [hidden]="!editGuarantor">
            <mat-form-field appearance="outline">
              <input
                matInput
                [disabled]="!editGuarantor"
                name="guarantorRelationship"
                [(ngModel)]="guarantor.relationship"
              />
            </mat-form-field>
          </div>
          <div [hidden]="editGuarantor">
            <jhi-null-replace
              [value]="guarantor.relationship"
            ></jhi-null-replace>
          </div>
        </ig-input>
      </div>
      <div class="col-3">
        <ig-input
          [inputField]="guarantorForm.controls.mobileNo"
          [inputErrors]="guarantorForm.controls.mobileNo?.errors"
        >
          <label
            jhiTranslate="kCredit.loanObligators.mobileNo"
            class="form-control-label"
            for="contactNumber"
            >Contact Number</label
          >
          <div [hidden]="!editGuarantor">
            <mat-form-field appearance="outline">
              <input
                matInput
                [disabled]="!editGuarantor"
                name="guarantorMobileNo"
                [(ngModel)]="guarantor.mobileNo"
              />
            </mat-form-field>
          </div>
          <div [hidden]="editGuarantor">
            <jhi-null-replace [value]="guarantor.mobileNo"></jhi-null-replace>
          </div>
        </ig-input>
      </div>
      <div class="col-3">
        <ig-input
          [inputField]="guarantorForm.controls.dateOfBirth"
          [inputErrors]="guarantorForm.controls.dateOfBirth?.errors"
        >
          <label
            jhiTranslate="kCredit.loanObligators.dateOfBirth"
            class="form-control-label"
            for="dob"
            >Date of birth (YYYY-MM-DD)</label
          >
          <div [hidden]="!editGuarantor" class="input-group">
            <mat-form-field class="date-picker" appearance="outline">
              <input
                matInput
                [disabled]="!editGuarantor"
                format="yyyy-MM-dd"
                [matDatepicker]="dateOfBirth"
                placeholder="Date of Birth"
                [max]="maxDate"
                [min]="minDate"
                (dateChange)="updateDate($event)"
                [value]="guarantor.dateOfBirth"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dateOfBirth"
              ></mat-datepicker-toggle>
              <mat-datepicker #dateOfBirth></mat-datepicker>
            </mat-form-field>
          </div>

          <div [hidden]="editGuarantor">
            <jhi-null-replace
              [value]="guarantor?.dateOfBirth | date : 'yyyy-MM-dd'"
            ></jhi-null-replace>
          </div>
        </ig-input>
      </div>
    </div>
  </div>
</form>
