<div class="card-title">

        <div *ngFor="let guarantor of guarantors; index as i" >
          <jhi-guarantor-member (reloadGuarantorAfterSave)="reloadGuarantorAfterSave($event)" [disableEdit]="disableEdit" [guarantor]="guarantor" [index]="i" (reloadAfterSave)="onSuccess($event)"></jhi-guarantor-member>
        </div>
    <span  *ngIf="!guarantors.length">--</span>
</div>
<div class="row row-spacing">
  <app-kyc-details-accordion [title]="'Guarantor'" [partnerId]="loanDetails?.customerDTO?.partnerId"
      [kycDocumentList]="loanDetails?.coapplicantKycDetailsList" [entityType]="'GUARANTOR'" [loanId]="loanDetails?.loanApplicationDTO?.id">
    </app-kyc-details-accordion>
</div>
