import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss']
})
export class SelectFieldComponent implements OnInit {
  @Input() dataObj: any = {};
  @Input() label: string = "";
  @Input() dropDownValues: Array<string> = [];
  @Input() isRequired: boolean = false;
  @Input() showSearchBox: boolean = false;

  filteredValues: Array<string> = [];
  searchFieldFormControl: FormControl = new FormControl();

  ngOnInit(): void {
    this.filteredValues = [...this.dropDownValues];
    this.searchFieldFormControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this.filteredValues = this.dropDownValues.filter((reason) => {
          return reason.toLowerCase().includes(value.toLowerCase());
        });
      });
  }
}
