import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CustomValidator } from "src/app/shared/validations/custom.validation";

@Component({
  selector: "app-input-field",
  templateUrl: "./input-field.component.html",
})
export class InputFieldComponent implements OnInit {
  @Input() dataObj: any = {};
  @Input() editable: boolean;
  @Input() label: string = "";
  @Input() isRequired: boolean = false;
  @Input() errors: any = {};
  @Input() inputLength: number;
  @Input() disabled: boolean = false;
  @Input() useRegex: boolean = false;
  @Input() customRegex: string = "";
  @Input() errorMessage: string = "Please enter a valid input.";

  inputFormControl: FormControl = new FormControl();

  constructor(readonly customValidator: CustomValidator) {}

  ngOnInit(): void {
    if(this.useRegex) {
      this.inputFormControl = new FormControl("", this.customValidator.regexValidator(this.customRegex));
    }
  }

}
