import { DomSanitizer } from "@angular/platform-browser";
import { Component, Input, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { get } from "lodash";
import { SECTION_INFORMATION } from "src/app/constants/ui-config";
import { getProperty } from "src/app/utils/app.utils";
import { DependableFieldValidationService } from "../../dependable-field-validation.service";
import { KcreditLoanDetailsModel } from "../../loan/kcredit-loanDetails.model";
import { LoanReviewService } from "../../report/loan-review.service";
import { BusinessRuleEngineService } from "../../services/business-rule-engine/business-rule-engine.service";
import { UiConfigService } from "../../services/ui-config.service";
@Component({
  selector: "app-bre",
  templateUrl: "./bre.component.html",
  styleUrls: ["./bre.component.scss"],
})
export class BreComponent implements OnInit {
  @Input() loanDetails: KcreditLoanDetailsModel;
  /** To be Reworked */
  @Input() partnerApplicationId: any = undefined;
  @Input() loanApplicationId: any = undefined;
  @Input() partnerCustomerId: any = undefined;
  @Input() breNeeded: boolean = false;
  @Input() enableRecalculateBre: boolean = true;
  @Input() fromEntry: boolean = false;
  @Input() editSections: boolean = false;

  loanId: number = null;

  enableEdit: boolean = false;

  breData: any = {};
  breAction: string = "";
  breStatus: any = "failed";
  enableViewReport: boolean = false;
  /** To be Reworked */

  openBreSection: boolean = true;

  eligibilityCalculations: any = {};
  eligibilityNorms: any = {};
  constructor(
    private matIconRegistry: MatIconRegistry,
    private uiConfigService: UiConfigService,
    private breService: BusinessRuleEngineService,
    private snackBar: MatSnackBar,
    private loanReviewService: LoanReviewService,
    private dependableFieldCheck: DependableFieldValidationService,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "pdf-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/common/bi_file-earmark-pdf.svg")
    );
  }

  async ngOnInit() {
    this.loanId = getProperty(this.loanDetails, "loanApplicationDTO.id", null);
    this.uiConfigService
      .getUiInformationBySections("BRE_DETAILS", this.loanId)
      .subscribe(
        (response: any) => {
          this.eligibilityNorms = getProperty(
            response,
            "subSections[0].fields",
            {}
          );

          this.eligibilityCalculations = getProperty(
            response,
            "subSections[1].fields",
            {}
          );
        },
        (error) => console.error(error)
      );
    /** To be Reworked */
    if (!this.breNeeded) return;

    let response = {};
    try {
      response = await this.breService
        .fetchBreCondition(this.partnerCustomerId, this.partnerApplicationId)
        .toPromise();
      this.breData = response;
      this.breAction = getProperty(this.breData, "decision", "FAILED") || "";
      this.breStatus = this.breAction.toLowerCase().includes("pass")
        ? "accept"
        : "failed";
      this.enableViewReport = !["PENDING", "FAILED"].includes(this.breAction);
      this.breService.setBreResponse(response);
    } catch (e) {
      console.error(e);
      this.breData = { decision: "FAILED" };
      this.breAction = getProperty(e, "error.message", "FAILED");
      this.enableViewReport = false;
    }
    /** To be Reworked */
  }

  openBreReport(event) {
    event.stopPropagation();
    const applicantName = this.loanReviewService.getApplicantName();
    this.breData = {
      ...this.breData,
      applicantName: applicantName,
      loanId: this.loanApplicationId,
      partnerAppId: this.partnerApplicationId,
      partnerCustomerId: this.partnerCustomerId,
    };
    const serializedData = window.btoa(
      encodeURIComponent(JSON.stringify(this.breData))
    );
    const queryParams = new URLSearchParams();
    queryParams.set("data", serializedData);
    const currentUrl = new URL(window.location.href);
    const targetUrl = new URL(`${currentUrl.href}/bre-report?${queryParams.toString()}`);
    if (targetUrl.origin === currentUrl.origin) {
      window.open(targetUrl.toString(), "_blank");
    } else {
      console.error("Attempted open redirect detected and prevented.");
    }
  }
  recalculateBre(event) {
    event.stopPropagation();
  }
  handleBreSection() {
    this.openBreSection = !this.openBreSection;
  }

  getPayload(): Object {
    const payload: any = {};
    Object.keys(this.eligibilityNorms).forEach((key) => {
      const value: string = get(this.eligibilityNorms[key], "value", null);
      payload[key] = value;
    });
    return payload;
  }

  save(): void {
    const payload: any = this.getPayload();
    this.uiConfigService
      .updateUiFields(
        SECTION_INFORMATION.BRE_WITH_ELIGIBILITY_NORMS.apiKey,
        payload,
        this.loanId
      )
      .subscribe(
        (response: any) => {
          const applcationStatus: string =
            this.loanReviewService.getLoanStatus();
          this.dependableFieldCheck.getLoanStageCheck(
            response,
            this.loanId,
            applcationStatus
          );

          this.snackBar.open(`Updated successfully`, "", {
            duration: 3000,
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
