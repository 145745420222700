<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input 
    matInput 
    [formControl]="inputFormControl" 
    [disabled]="!editable || disabled" 
    [maxlength]="inputLength" 
    [required]="isRequired"
    [(ngModel)]="dataObj.value" 
  />
</mat-form-field>
<mat-error *ngIf="inputFormControl.errors?.['invalidPattern']">
  {{errorMessage}}
</mat-error>
