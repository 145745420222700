<div class="document-subtype-container">
  <div>{{ subtype ? "Document Type - " + subtype : "" }}</div>
  <div class="document-action-button-container" *ngIf="hideRejectAllSection()">
    <button mat-raised-button class="btn-secondary" [hidden]="!checkForTags() || isApplicationAssigned"
      (click)="tagAllDocuments()" [disabled]="!disableEditAccess">
      Tag All
    </button>
    <mat-checkbox class="example-margin" [checked]="rejectAll" (change)="updateRejectAll($event.checked)"
      [disabled]="!disableEditAccess || disableButtonsForTags || checkForTags() || !isApplicationAssigned">Reject
      All</mat-checkbox>
    <button mat-raised-button class="btn-primary" [disabled]="
        !disableEditAccess ||
        disableButtonsForTags ||
        validateRejectAll() ||
        checkForTags() || !isApplicationAssigned
      " (click)="updateDocument()">
      {{ rejectAll ? "Reject All" : "Accept All" }}
    </button>
  </div>
</div>
<div class="document-container">
  <div class="item" *ngFor="let doc of documents">
    <div *ngIf="doc.fileId || doc.documentFileId">
      <app-doc-title-container [doc]="doc" [documentCategory]="documentCategory" [id]="loanId"
        [disableEditButtons]="checkForTags()" (editImage)="editImage(doc, 'CROP')"
        [hideEditButtons]="checkAndDisableActions(doc)" [docType]="doc?.docType"
        [isApplicationAssigned]="isApplicationAssigned"></app-doc-title-container>
      <br />
      <div class="image-container" *ngIf="doc.image">
        <img *ngIf="!isPdf(doc)" class="d-inline-block nach-image fill-image" [src]="doc.image" alt="Doc" />
        <img *ngIf="isPdf(doc)" class="d-inline-block nach-image fill-image" [src]="doc.fileIcon"
          [alt]="doc.documentName" />
      </div>
      <br />
      {{ doc?.documentName }} &nbsp; &#x2022; &nbsp;
      <span [class]="doc?.docSizeClass">{{ doc?.size }}</span>
      <div class="document-subtype-container">
        <mat-checkbox class="example-margin" [(ngModel)]="doc.rejectStatus"
          [hidden]="checkAndDisableActions(doc) || !isApplicationAssigned" [disabled]="
            !disableEditAccess || disableButtonsForTags || checkForTags() || !isApplicationAssigned
          ">Reject
        </mat-checkbox>
        <button mat-raised-button class="btn-primary" (click)="updateDocument(doc)"
          [hidden]="checkAndDisableActions(doc)" [disabled]="
            !disableEditAccess ||
            disableButtonsForTags ||
            doc.rejectStatus ||
            checkForTags() || !isApplicationAssigned
          ">
          Accept
        </button>
      </div>
      <div class="document-subtype-container">
        <div *ngIf="
            doc.reviewStatus &&
            (doc.reviewStatus === 'ACCEPT' || doc.reviewStatus === 'REJECT')
          ">
          <label class="form-control-label" for="doc.reviewStatus"><span>{{
              doc.reviewStatus === "ACCEPT" ? "Accepted" : "Rejected"
              }}</span></label>
          <br />
          <label *ngIf="doc.reviewStatus === 'REJECT'" for="rejectReason"><span>Reason - {{ doc.reviewRemarks }}
            </span></label>
        </div>
        <div *ngIf="validateReasonInput(doc)">
          <mat-form-field>
            <input required matInput placeholder="Reject Reason" [(ngModel)]="doc.reviewRemarks" style="width: 100%"
              [disabled]="checkAndDisableActions(doc) || checkForTags() || !isApplicationAssigned" />
            <mat-error *ngIf="
                doc.reviewRemarks === null ||
                (doc.reviewRemarks && doc.reviewRemarks.length < 1)
              ">
              Please enter a reason for rejecting the image.
            </mat-error>
          </mat-form-field>
          <br />
          <button mat-raised-button class="btn-primary" (click)="updateDocument(doc)" [disabled]="
              !disableEditAccess ||
              disableButtonsForTags ||
              !(
                !checkAndDisableActions(doc) &&
                doc.reviewRemarks &&
                doc.reviewRemarks.length > 0
              ) ||
              checkForTags() || !isApplicationAssigned
            ">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>