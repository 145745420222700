<div>
    <mat-form-field class="custom-form-field"
         appearance="standard">
        <input [formControl]="inputFormControl" matInput type="text"
         [value]="valueObj?.[key]" (input)="checkInput($event)" />
    </mat-form-field>
    <mat-error *ngIf="inputFormControl?.hasError('min')"> 
        EMI amount cannot be lesser than interest
    </mat-error>
    <mat-error *ngIf="inputFormControl?.hasError('max')"> 
        EMI amount cannot be greater than loan amount
    </mat-error>
</div>