import { Component } from "@angular/core";

@Component({
  selector: "ig-main",
  templateUrl: "./main.component.html",
})
export class IgMainComponent {
  filteredEndpoints: string[] = [
    ".*/api/lender/ui/loan-review/info-section/*",
    ".*/api/anonymous/lender/ui/.*",
  ];
}
