<ng-container>
    <div *ngIf="paymentSchedule.length > 0">
        <h3 class="section-sub-heading">Payment Schedule</h3>
        <div class="table-container">
            <table>
                <caption></caption>
                <thead>
                    <tr>
                        <th class="table-heading" *ngFor="let column of tableMap">
                            <span>
                                {{ column?.label }}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let schedule of paymentSchedule; let i = index">
                        <ng-container *ngFor="let column of tableMap" [ngSwitch]="column?.type">
                            <td *ngSwitchDefault>
                                <jhi-null-replace [value]="schedule?.[column?.propertyKey]
                                     | dynamicDataTransform : column?.displayFormat">
                                </jhi-null-replace>
                            </td>
                            <td *ngSwitchCase="'dateEdit'">
                                <jhi-null-replace [hidden]="enableEdit && i === 0" [value]="schedule?.[column?.propertyKey]
                                    | dynamicDataTransform : column?.displayFormat">
                                </jhi-null-replace>
                                <app-repayment-table-date-field *ngIf="enableEdit && i === 0" [valueObj]="schedule"
                                    [key]="column?.propertyKey" [disableEdit]="disableEdit" (saveDetails)="saveDetails.emit(i)">
                                </app-repayment-table-date-field>
                            </td>
                            <td *ngSwitchCase="'emiEdit'">
                                <jhi-null-replace [hidden]="enableEdit && i < (paymentSchedule?.length - 1)"
                                     [value]="schedule?.[column?.propertyKey]
                                    | dynamicDataTransform : column?.displayFormat">
                                </jhi-null-replace>
                                <app-repayment-table-input-field *ngIf="enableEdit && i < (paymentSchedule?.length - 1)"
                                    [valueObj]="schedule" [key]="column?.propertyKey" (saveDetails)="saveDetails.emit(i)"
                                    [finalLoanAmount]="finalLoanAmount" [disableEdit]="disableEdit"
                                >
                                </app-repayment-table-input-field>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>