import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import {
  GET_KYC_VERIFICATION_STATUS,
  KYC_CONFIG,
  VERIFY_KYC,
} from "src/app/shared/constants/Api.constants";

export const REVIEW_STATUS = ["REJECT", "Rejected", "ACCEPT", "Accepted"];

@Injectable({
  providedIn: "root",
})
export class KycVerificationService {
  constructor(private http: HttpClient, private snackbar: MatSnackBar) {}

  getKycVerificationStatus(
    loanId: number = null,
    entityId: number = null,
    entityType: string = null
  ) {
    const params = new HttpParams()
      .append("entityType", entityType)
      .append("entityId", entityId);
    return this.http.get(`${GET_KYC_VERIFICATION_STATUS}/${loanId}`, {
      params,
    });
  }

  verifyKyc(requestPayload: any = null): Observable<any> {
    return this.http.post(`${VERIFY_KYC}`, requestPayload);
  }

  getKycVerificationConfig(loanId: number = null) {
    return this.http.get(`${KYC_CONFIG}/${loanId}`);
  }
}
