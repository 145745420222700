import { Component, Input, OnInit } from "@angular/core";
import { UiConfigService } from "../../services/ui-config.service";
import {
  SECTION_INFORMATION,
  UiField,
  UiFieldsDto,
} from "src/app/constants/ui-config";
import { getProperty } from "src/app/utils/app.utils";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthorizationService } from "../../services/authorization.service";
import { ApplicationStatus } from "../../loan/constant";
import { LoanReviewService } from "../../report/loan-review.service";

@Component({
  selector: "app-customer-demand-schedule",
  templateUrl: "./customer-demand-schedule.component.html",
  styleUrls: ["./customer-demand-schedule.component.scss"],
})
export class CustomerDemandScheduleComponent implements OnInit {
  @Input() loanId: number = null;
  @Input() editSections: boolean = false;

  emiType: string = null;
  finalLoanAmount: number = null;
  panelOpenState: boolean = true;
  editDetails: boolean = false;
  demandSchedule: any = {};
  paymentSchedule: Array<any> = [];
  uiFieldsMap: Array<any> = [];
  initialSchedule: any = {};
  hasAuthority: boolean = false;
  disableEditOnCall: boolean = false;
  isStaggered: boolean = false;
  constructor(
    private uiConfigService: UiConfigService,
    private snackBar: MatSnackBar,
    private readonly authorizationService: AuthorizationService,
    private readonly loanReviewService: LoanReviewService
  ) {}

  ngOnInit(): void {
    this.hasAuthority =
      this.authorizationService.hasAuthority(
        SECTION_INFORMATION.DEMAND_SCHEDULE.authority
      ) &&
      this.loanReviewService.getLoanStatus() !==
        ApplicationStatus.pendingagreement;
    this.uiConfigService
      .getUiInformationBySections(
        SECTION_INFORMATION.DEMAND_SCHEDULE.sectionKey,
        this.loanId
      )
      .subscribe(
        (response: any) => {
          const subSection: Array<any> = getProperty(
            response,
            "subSections",
            []
          );
          this.setUiFields(subSection);
        },
        (error) => console.error(error)
      );

    this.uiConfigService
      .getUiConfigBySection(SECTION_INFORMATION.DEMAND_SCHEDULE.sectionKey)
      .subscribe((response: any = {}) => {
        const uiConfig = this.uiConfigService.getUiConfigurationsBySection(
          response,
          SECTION_INFORMATION.DEMAND_SCHEDULE.sectionKey,
          true
        );
        this.uiFieldsMap = getProperty(uiConfig, "uiFieldsMap", []);
      });
  }

  setUiFields(subSection: Array<any>): void {

    const exceptions: UiField = getProperty(
      subSection?.[0],
      "fields.exceptionMessageHandler",
      null
    );
    if (exceptions) {
      const errors: Array<any> = exceptions?.value ?? [];
      Object.keys(this.demandSchedule).forEach((key) => {
        const error = errors.find((error) => {
          return error?.exceptionFieldName === key;
        });
        if (error) {
          this.demandSchedule[key].error = error?.exceptionMessage ?? null;
        } else {
          delete this.demandSchedule[key].error;
        }
      });
      return;
    }
    this.demandSchedule = getProperty(subSection[0], "fields", {});
    this.initialSchedule = JSON.parse(JSON.stringify(this.demandSchedule));
    this.emiType = getProperty(this.demandSchedule, "emiType.value", null);
    this.isStaggered = this.emiType?.toLowerCase() === "non-emi";
    this.finalLoanAmount = getProperty(
      this.demandSchedule,
      "finalLoanAmount.value",
      null
    );
    const paymentScheduleField = getProperty(subSection[1], "fields", {});
    this.paymentSchedule = getProperty(
      paymentScheduleField,
      "repaymentScheduleList.value",
      []
    );
  }

  toggleEditDetails(event: Event) {
    event.stopPropagation();
    this.editDetails = !this.editDetails;
  }

  cancel(event: Event) {
    event.stopPropagation();
    this.editDetails = !this.editDetails;
    this.demandSchedule = JSON.parse(JSON.stringify(this.initialSchedule));
  }

  getPayload(index: Event | number): Object {
    const payload: Object = {};
    Object.keys(this.demandSchedule).forEach((key) => {
      const value = getProperty(this.demandSchedule[key], "value", null);
      payload[key] = value;
    });

    if (
      this.emiType.toLowerCase() === "non-emi" &&
      typeof index === "number"
    ) {
      payload["emiAmount"] = this.paymentSchedule[index]?.emiAmount;
      payload["emiStartDate"] =
        index === 0
          ? this.paymentSchedule[index]?.dueDate
          : this.demandSchedule?.emiStartDate?.value;
      payload["demandNumber"] = this.paymentSchedule[index]?.demandNumber;
    }
    return payload;
  }

  save(event: Event | number, closeEdit: boolean = false) {
    this.disableEditOnCall = true;
    if (event instanceof Event) {
      event?.stopPropagation();
    }
    const payload = this.getPayload(event);
    this.uiConfigService
      .updateUiFields(
        SECTION_INFORMATION.DEMAND_SCHEDULE.apiKey,
        payload,
        this.loanId
      )
      .subscribe(
        (response: any) => {
          this.editDetails = !closeEdit;
          const sectionDTO: UiFieldsDto = getProperty(
            response,
            "sectionDto",
            {}
          );
          const subsection: Array<UiFieldsDto> = sectionDTO?.subSections ?? [];
          this.setUiFields(subsection);
          this.snackBar.open(`Updated successfully`, "", {
            duration: 3000,
          });
          this.disableEditOnCall = false;
          if(event instanceof Event) {
            location.reload();
          }
        },
        (error) => {
          console.error(error);
          this.demandSchedule = JSON.parse(
            JSON.stringify(this.initialSchedule)
          );
          this.snackBar.open(`Error updating Demand Schedule`, "", {
            duration: 3000,
          });
          this.disableEditOnCall = false;
        }
      );
  }
}
