import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { get } from "lodash";
import { JhiAlertService } from "ng-jhipster";
import { updateName } from "src/app/shared";
import { AddressType } from "../../../models/customer/address-enum.model";
import { Address } from "../../../models/customer/address.model";
import { Customer } from "../../../models/customer/customer.model";
import { FamilyDetails } from "../../../models/customer/family-details.model";
import { KycDetailsForLoan } from "../../../models/kyc-details.model";
import { KCreditLoanApplication } from "../../../report/kcredit-loan-application.model";
import { CustomerService } from "../../../services/customer/customer.service";
import { DefaultErrorStateMatcher, Errors } from "../../constant";
import { AssociateLenderService } from "../../../services/associate-lender/associate-lender.service";
import { getProperty } from "src/app/utils/app.utils";

@Component({
  selector: "jhi-basic-customer-info",
  templateUrl: "./basic-customer-info.component.html",
  styleUrls: ["../../kcredit-loan.css"],
})
export class BasicCustomerInfoComponent implements OnInit, OnChanges {
  @Input() addressList: Address[];
  @Input() familyDetailsList: FamilyDetails[];
  @Input() disableEdit: boolean;
  @Input() customer: Customer;
  @Input() kycDetailsList: KycDetailsForLoan[];
  @Input() loanApplicationDTO: KCreditLoanApplication;
  @Output() reloadAfterSave = new EventEmitter<any>();
  public initialCustomer: Customer;
  panelOpenState: boolean = true;
  readonly errorConstants = Errors;
  public errorMatcher = null;
  customerInfoForm: FormGroup;
  genders: any;
  maritalStatuses: any;
  referenceCodeClassifiers: any;
  maxDate;
  minDate;
  year;
  month;
  day;
  enableEdit: boolean = false;
  error: boolean;
  docId: any = { poi: null, poa: null };
  docType: any = { poi: null, poa: null };
  editCurrentAddress: boolean = false;
  editBusinessAddress: boolean = false;
  editPermanentAddress: boolean = false;
  //to be worked on
  public dateDisplay = undefined;
  fatherName: string = "";
  spouseName: string = "";

  isAadhaarVerificationNeeded: boolean = false;

  constructor(
    private customerService: CustomerService,
    private alertService: JhiAlertService,
    private associateLenderService: AssociateLenderService
  ) {
    this.errorMatcher = new DefaultErrorStateMatcher();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let familyDetailsChanges = changes["familyDetailsList"];
    const previousObject = JSON.stringify(familyDetailsChanges.previousValue);
    const currentObject = JSON.stringify(familyDetailsChanges.currentValue);

    if (previousObject !== currentObject && !familyDetailsChanges.firstChange) {
      this.updateFamilyName("Father", "fatherName", this.customer);
      this.updateFamilyName("Spouse", "spouseName", this.customer);
    }
  }

  ngOnInit() {
    if (this.customer) {
      this.dateDisplay = new Date(this.customer.dateOfBirth);
      if (typeof this.customer.dateOfBirth === "string") {
        this.customer.dateOfBirth =
          this.customerService.convertLocalDateFromServer(
            this.customer.dateOfBirth
          );
      }
    }
    this.referenceCodeClassifiers = ["Gender", "MaritalStatus"];
    this.customerService
      .getAllReferanceCodes(this.referenceCodeClassifiers)
      .subscribe((res) => {
        this.genders = res.Gender;
        this.maritalStatuses = res.MaritalStatus;
      });

    this.maxDate = new Date();
    this.minDate = new Date(1930, 1, 1);

    if (this.addressList) {
      this.addressList.map((address) => {
        if (
          address.addressType === AddressType.PERMANENT ||
          address.addressType === AddressType.CURRENT ||
          address.addressType === AddressType.BUSINESS
        ) {
          return address;
        }
        return null;
      });
    }
    this.getDocumentId();

    this.updateFamilyName("Father", "fatherName", this.customer);
    this.updateFamilyName("Spouse", "spouseName", this.customer);
    this.initialCustomer = { ...this.customer };
    this.associateLenderService
      .getLenderConfigurationSubject()
      .subscribe((configuration) => {
        const aadhaarVerification: boolean = getProperty(
          configuration,
          "isAadhaarVerificationNeeded",
          false
        );
        this.isAadhaarVerificationNeeded = aadhaarVerification;
      });
  }

  convertLocalDateFromServer(date: string) {
    if (date !== undefined && date != null) {
      const split = date.split("-");
      const dateObj = {
        year: parseInt(split[0]),
        month: parseInt(split[1]),
        day: parseInt(split[2]),
      };
      return dateObj;
    } else {
      return undefined;
    }
  }

  toggleEditDetails(event) {
    event.stopPropagation();
    this.enableEdit = !this.enableEdit;
  }

  cancelEdit() {
    this.closeEdit();
    this.customer = { ...this.initialCustomer };
  }

  closeEdit() {
    this.enableEdit = false;
  }
  cancel(event: Event): void {
    event.stopPropagation();
    this.cancelEdit();
  }
  save(event) {
    event.stopPropagation();
    this.saveCustomerInfo();
  }
  saveFamilyDetails(
    relationshipType = "Father",
    instance = {},
    instanceKey = "fatherName"
  ) {
    if (get(this.familyDetailsList, "length")) {
      let familyDto = this.familyDetailsList.find(
        (familyDetail) => familyDetail.relationship === relationshipType
      );
      if (familyDto && instance[instanceKey] && familyDto["relationName"]) {
        familyDto["relationName"] = instance[instanceKey];
        familyDto = updateName(familyDto, "relationName");
        this.customerService.updateFamily(familyDto).subscribe();
      }
    }
  }
  saveCustomerInfo() {
    this.saveFamilyDetails("Father", this.customer, "fatherName");
    this.saveFamilyDetails("Spouse", this.customer, "spouseName");

    this.customerService.update(this.customer).subscribe(
      (res) => {
        this.onSuccess(res, "Customer Info");
      },
      (error) => this.onSaveError(error)
    );
  }

  getDocumentId(): string {
    if (this.kycDetailsList) {
      let poiData = this.kycDetailsList.find(
        (kycInfo) => kycInfo.purpose === "POI"
      );
      this.docId["poi"] = poiData.idNo;
      this.docType["poi"] = poiData.documentType;
      let poaData = this.kycDetailsList.find(
        (kycInfo) => kycInfo.purpose === "POA"
      );
      this.docId["poa"] = poaData.idNo;
      this.docType["poa"] = poaData.documentType;
    }
    return this.docId;
  }

  private onSaveError(error) {
    try {
      error.message = error.error;
    } catch (exception) {
      console.log(error);
    }
    this.onError(error);
  }

  private onError(error) {
    this.error = false;
    setTimeout(() => {
      this.alertService.error(error.error, null, null);
    }, 100);
  }

  onSuccess(res, entity) {
    this.closeEdit();
    this.reloadAfterSave.emit(entity);
  }

  updateDate(dateObject = {}) {
    let date = dateObject["value"];
    this.customer["dateOfBirth"] = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  updateFamilyName(
    relationshipType = "Spouse",
    instanceKey = "fatherName",
    instance = this.customer,
    relationshipKey = "relationName"
  ) {
    if (get(this.familyDetailsList, "length")) {
      let familyDto = this.familyDetailsList.find(
        (familyDetail) => familyDetail.relationship === relationshipType
      );
      if (familyDto) {
        if (get(familyDto, relationshipKey)) {
          instance[instanceKey] = `${get(familyDto, relationshipKey)}`;
          if (get(familyDto, "middleName", null)) {
            instance[instanceKey] += ` ${get(familyDto, "middleName", "")}`;
          }
          if (get(familyDto, "lastName", null)) {
            instance[instanceKey] += ` ${get(familyDto, "lastName", "")}`;
          }
        }
      }
    }
  }
}
