import { Injectable, Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class LoanReviewPopupService {
  private isOpen = false;
  constructor(private modalService: NgbModal) {}

  open(component: Component, data?: any, ...rest): NgbModalRef {
    if (this.isOpen) {
      return null;
    }
    this.isOpen = true;
    const [
      checkedListWithEligibilityPassed,
      checkedListWithEligibilityFailed,
      eligibilityFailedForLoanApplication,
      jlgMinimumSizeBreachedList,
      metaData = {},
      rejectReasons = [],
      customClass = "",
    ] = rest;
    return this.cropperModalRef(
      component,
      data,
      checkedListWithEligibilityPassed,
      checkedListWithEligibilityFailed,
      eligibilityFailedForLoanApplication,
      jlgMinimumSizeBreachedList,
      metaData,
      rejectReasons,
      customClass
    );
  }

  cropperModalRef(component: Component, data, ...rest): NgbModalRef {
    const [
      checkedListWithEligibilityPassed,
      checkedListWithEligibilityFailed,
      eligibilityFailedForLoanApplication,
      jlgMinimumSizeBreachedList,
      metaData = {},
      rejectReasons = [],
      customClass = "",
    ] = rest;
    const modalRef = this.modalService.open(component, {
      size: "lg",
      backdrop: "static",
      windowClass: customClass,
    });
    modalRef.componentInstance.noOfEntries = data;
    modalRef.componentInstance.checkedListWithEligibilityPassed =
      checkedListWithEligibilityPassed;
    modalRef.componentInstance.checkedListWithEligibilityFailed =
      checkedListWithEligibilityFailed;
    modalRef.componentInstance.eligibilityFailedForLoanApplication =
      eligibilityFailedForLoanApplication;
    modalRef.componentInstance.jlgMinimumSizeBreachedList =
      jlgMinimumSizeBreachedList;
    modalRef.componentInstance.metaData = metaData;
    modalRef.componentInstance.rejectReasons = rejectReasons;

    modalRef.result.then(
      () => {
        this.isOpen = false;
      },
      () => {
        this.isOpen = false;
      }
    );
    return modalRef;
  }
}
