import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Errors } from "../../loan/constant";

@Component({
  selector: "app-co-applicant-details",
  templateUrl: "./co-applicant-details.component.html",
  styleUrls: ["./co-applicant-details.component.scss"],
})
export class CoApplicantDetailsComponent implements OnInit {
  @Input() coApplicant: any = {};
  @Input() disableEdit: boolean;
  @Input() title: any = "";
  @Output() saveCoApplicantDetails: EventEmitter<any> = new EventEmitter<any>();

  readonly errorConstants = Errors;

  public errorMatcher = null;
  editCoApplicantDetails: boolean = false;
  initialCoApplicant: any = {};
  maxDate: Date;
  minDate: Date;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.maxDate = new Date();
    this.minDate = new Date(1930, 1, 1);
    this.initialCoApplicant = JSON.parse(JSON.stringify(this.coApplicant));
  }

  updateDate(dateObj = {}): void {
    if (dateObj["value"]) {
      const date: Date = new Date(dateObj["value"]);
      const dateString = this.datePipe.transform(date, "yyyy-MM-dd");
      this.coApplicant["dateOfBirth"] = dateString;
    }
  }

  saveCoApplicant(): void {
    this.saveCoApplicantDetails.emit(this.coApplicant);
  }

  enableCoApplicantEdit(): void {
    this.editCoApplicantDetails = true;
  }

  cancelCoApplicantEdit(): void {
    this.coApplicant = { ...this.initialCoApplicant };
    this.editCoApplicantDetails = false;
  }
}
