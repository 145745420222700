import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { groupObjectsWithMultiParams } from "src/app/shared/util/loan-application-documents-utils";
import { getProperty } from "src/app/utils/app.utils";
import { UploadService } from "../../../services/upload.service";
import { KcreditLoanDetailsModel } from "../../kcredit-loanDetails.model";
import { UploadReportPopupComponent } from "../upload-reports/upload-report-popup/upload-report-popup.component";
import { AuthorizationService } from "../../../services/authorization.service";

@Component({
  selector: "jhi-additional-documents",
  templateUrl: "./additional-documents.html",
  styleUrls: ["../../kcredit-loan.css"],
})
export class AdditionalDocumentComponent {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() disableEdit: boolean;
  @Input() loanId: any;
  @Input() partnerId: any;
  @Input() editSections: boolean = true;

  disableAddDocuments: boolean = false;
  panelOpenState: boolean = false;
  documentTypes: any[] = [];
  documentMap: any = {};
  selectedDocuments: Array<any> = [];

  constructor(
    public domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private fileUploadService: UploadService,
    private authorityService:AuthorizationService
  ) {}

  ngOnInit() {
    if (this.loanDetails) {
      // Fetching Documents -Additional Documents
      let loanDocuments: Array<any> = getProperty(
        this.loanDetails,
        "loanApplicationDocumentDTOList",
        []
      );
      loanDocuments = loanDocuments.concat(
        getProperty(
          this.loanDetails,
          "coapplicantLoanApplicationDocumentDTOList",
          []
        )
      );
      if (loanDocuments) {
        loanDocuments = loanDocuments.filter(
          (doc) => doc && !doc.loanDocumentStage
        );
      }
      if (loanDocuments) {
        let docs = groupObjectsWithMultiParams(loanDocuments, "documentType");
        delete docs.items;
        this.documentTypes = Object.keys(docs);
        this.documentMap = docs;
      }
      this.disableAddDocuments = this.authorityService.validateEditAccess();
    }

  }

  receiveFiles(files: Array<any> = []) {
    this.selectedDocuments = files;
    this.fileUploadService.uploadFilesToS3(files);
  }
  addAdditionalDocuments(event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(UploadReportPopupComponent, {
      minWidth: "45vw",
      maxHeight: "80vh",
      data: {
        reportsRouteUrl: "this.reportsRouteUrl",
        acceptedFileTypes: [
          "pdf",
          "doc",
          "docx",
          "xls",
          "xlsx",
          "csv",
          "eml",
          "jpeg",
          "jpg",
        ],
        uploadType: "RECEIVE_FILES",
        dialogTitle: "Upload Documents",
        canAllowMultiple: false,
        receiveFiles: (event) => this.receiveFiles(event),
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log("The dialog was closed");
    });
  }
}
