export const DEVIATION_TABLE_DATA: any = [
  {
    label: "",
    type: "checkbox",
  },
  {
    label: "Rule Description",
    type: "text",
    propertyKey: "ruleDescription",
  },
  {
    label: "Variable Name",
    type: "var",
    propertykey: "deviationTypeAndValue",
  },
  {
    label: "Variable Actual Value",
    type: "default",
    propertyKey: "value",
  },
  {
    label: "Approve Deviation",
    type: "approve",
    propertyKey: "approveDeviations",
  },
  {
    label: "Remarks",
    type: "default",
  },
];
