<div>
  <form name="coApplicantDetailForm"  novalidate #coApplicantDetailForm="ngForm">
  <div
    *ngFor="let coApplicant of coApplicants; let i = index"
    class="card-title"
  >
  <app-co-applicant-details
  [coApplicant] = "coApplicant"
  [title]="'Co-applicant ' + (i + 1)"
  (saveCoApplicantDetails) = "saveCoApplicantDetails($event,i)"
  >
  </app-co-applicant-details>
  <span *ngIf="!coApplicants.length">--</span>
  <div class="row row-spacing">
    <app-kyc-details-accordion [title]="'Coapplicant'" [partnerId]="loanDetails?.customerDTO?.partnerId"
      [kycDocumentList]="coApplicant?.kycDocuments" [entityType]="'CO_APPLICANT'" [loanId]="loanId">
    </app-kyc-details-accordion>
  </div>
</div>
  </form>
</div>