import { Component, Input, OnInit } from '@angular/core';
import { KycDetailsForLoan } from '../../models/kyc-details.model';
import { getProperty } from 'src/app/utils/app.utils';

@Component({
  selector: "app-kyc-details",
  templateUrl: "./kyc-details.component.html",
  styleUrls: ["./kyc-details.component.scss"],
})
export class KycDetailsComponent implements OnInit {
  @Input() loanDetails: any = {};
  @Input() loanId: number;

  coApplicantKycDocumentList: Array<KycDetailsForLoan> =
    new Array<KycDetailsForLoan>();

  ngOnInit(): void {
    this.coApplicantKycDocumentList = getProperty(
      this.loanDetails,
      "coapplicantKycDetailsList",
      []
    );
  }
}
