import { Component, Input, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UI_COMPONENTS } from "src/app/constants/ui-config";
import { getProperty } from "src/app/utils/app.utils";
import { BusinessRuleEngineService } from "../../services/business-rule-engine/business-rule-engine.service";
import { UiConfigService } from "../../services/ui-config.service";
import { DEVIATION_TABLE_DATA } from "./deviations.constants";
import { AUTHORITES } from "../../constants/authorization.constants";
import { AuthorizationService } from "../../services/authorization.service";
import { get } from "lodash";

@Component({
  selector: "app-deviations-table",
  templateUrl: "./deviations-table.component.html",
  styleUrls: ["./deviations-table.component.scss"],
})
export class DeviationsTableComponent implements OnInit {
  @Input() loanId: number = null;
  deviations: Array<any> = [];
  tableData: Array<any> = DEVIATION_TABLE_DATA;

  currentDeviations: Array<any> = [];
  checkAll: boolean = false;
  checkedDeviations: Array<any> = [];
  authority: { approve: boolean } = { approve: false };

  constructor(
    private breService: BusinessRuleEngineService,
    private snackbar: MatSnackBar,
    private uiConfigService: UiConfigService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    this.uiConfigService
      .getUiInformationBySections(UI_COMPONENTS.DEVIATIONS, this.loanId)
      .subscribe((response: any) => {
        this.deviations = getProperty(
          response,
          "subSections[0].fields.breDeviationsSectionList.value",
          []
        );
        this.splitDeviations();
      });
    this.authority.approve = this.authorizationService.validateEditAccess() && this.authorizationService.hasAuthority(
      AUTHORITES.APPROVE_DEVIATIONS
    );
  }
  splitDeviations(): void {
    this.currentDeviations =
      this.deviations.length > 5
        ? this.deviations.slice(0, 5)
        : this.deviations;
  }

  checkAllDeviations() {
    this.checkAll = !this.checkAll;
    if (this.checkAll) {
      this.checkedDeviations = this.currentDeviations.filter((deviation) => {
        const approveDeviations = getProperty(
          deviation,
          "approveDeviations",
          []
        );
        return (
          getProperty(
            approveDeviations,
            "[0].approveDeviation",
            ""
          ).toLowerCase() === "pending" &&
          get(approveDeviations, "[0].enableDeviationApproval", false)
        );
      });
    } else {
      this.checkedDeviations = [];
    }
  }

  selectDeviation( checkEvent: MatCheckboxChange,deviation: any = {}): void {
    if (checkEvent.checked) {
      this.checkedDeviations.push(deviation);
    } else {
      this.checkedDeviations = this.checkedDeviations.filter(
        (item) => item.deviationId !== deviation.deviationId
      );
    }
  }

  showMoreDeviations(): void {
    this.currentDeviations = this.deviations;
  }

  handleDeviationDecision(deviations: Array<any>, decision: string): void {
    const changeDecision = (approveDecision: any) => ({
      ...approveDecision,
      approveDeviation: decision,
    });

    const deviationDecision = deviations.map((deviation) => ({
      ...deviation,
      approveDeviations: getProperty(deviation, "approveDeviations", []).map(
        changeDecision
      ),
    }));
    const payload: any = { breDeviationsSectionList: deviationDecision };
    this.breService.postDeviationRemarks(payload).subscribe(
      () => {
        deviationDecision.forEach((deviation) => {
          const index = this.deviations.findIndex(
            (value) => value.deviationId === deviation.deviationId
          );
          if (index !== -1) {
            this.deviations[index] = deviation;
          }
        });
      },
      (error) => {
        console.error(error);
        this.snackbar.open(
          getProperty(
            error,
            "error.message",
            "Error while remarking deviations"
          ),
          "",
          { duration: 3000 }
        );
      }
    );
  }
}
