import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { get } from "lodash";
import {
  groupObjectsWithMultiParams
} from "src/app/shared/util/loan-application-documents-utils";
import { DocReviewType } from "../../../models/customer-group/review-enum.model";
import { CustomerFileMapping } from "../../../models/customer/customer-file-mapping.model";
import { KycDetailsForLoan } from "../../../models/kyc-details.model";
import { ReferenceCode } from "../../../models/reference-code/reference-code.model";
import { SubscriptionReviewSupportService } from "../../../services/customer-group/subscription-review/subscription-review-support.service";
import { SubscriptionReviewService } from "../../../services/customer-group/subscription-review/subscription-review.service";
import { FileService } from "../../../services/files/file.service";
import { RejectReasonPipe } from "../../../shared/pipes/ig-custom-refcode.pipe";
import { KcreditLoanDetailsModel } from "../../kcredit-loanDetails.model";
import { ImageEditorPopupService } from "../image-editor/image-editor-popup.service";
import { ImageEditorComponent } from "../image-editor/image-editor.component";
import { getProperty } from "src/app/utils/app.utils";

@Component({
  selector: "jhi-kyc",
  templateUrl: "./kyc.component.html",
  styleUrls: ["../../kcredit-loan.css", "./kyc.component.css"],
})
export class KycComponent {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() disableEdit: boolean;
  @Input() loanId: any;
  @Input() partnerId: any;
  @Input() customerId: number;

  referenceCodeClassifiers: any;
  genders: ReferenceCode[];
  maritalStatuss: ReferenceCode[];
  educations: ReferenceCode[];
  occupations: ReferenceCode[];
  cities: ReferenceCode[];
  states: ReferenceCode[];
  districts: ReferenceCode[];
  countries: ReferenceCode[];
  bankNames: ReferenceCode[];
  accountTypes: ReferenceCode[];
  nomineeRelationships: ReferenceCode[];
  kycRejectReasons: ReferenceCode[] = [];
  customerFileMappingDtoList: CustomerFileMapping[];
  kycDetailsList: KycDetailsForLoan[];
  customerFileMappingDtoListMap: Map<string, CustomerFileMapping> = new Map<
    string,
    CustomerFileMapping
  >();
  poaDocuments: any = [];
  poiDocuments: any = [];
  modalRef: NgbModalRef;
  docReviewStatus: any;
  updatedDocument: any = [];
  fileDTOs: any[];
  disableAcceptKyc: boolean = false;

  poiPanelOpenState: boolean = true;
  poaPanelOpenState: boolean = true;
  documentTypes: any[] = [];
  constructor(
    public domSanitizer: DomSanitizer,
    private customerReviewService: SubscriptionReviewService,
    private imageEditorPopupService: ImageEditorPopupService,
    private rejectReasonPipe: RejectReasonPipe,
    private subscriptionReviewSupportService: SubscriptionReviewSupportService,
    private fileService: FileService
  ) {}

  ngOnInit() {
    this.customerFileMappingDtoList = get(
      this.loanDetails,
      "customerFileMappingDTOList",
      []
    );
    this.updatedDocument = [];
    this.getCustomerFileMappingImages();
  }
  checkAnyPendingDocs() {
    let kycDocList = get(this.loanDetails, "kycDetailsList", []) || [];
    return kycDocList.every((doc) => doc.verificationStatus !== "Pending");
  }

  fetchDocReviewStatus() {
    this.customerReviewService
      .getDocReviewStatus(
        this.loanDetails.customerDTO.id,
        DocReviewType.KYC,
        "IMAGE",
        null,
        null
      )
      .subscribe((res) => {
        this.disableAcceptKyc =
          ["REJECT", "ACCEPT"].indexOf(get(res, "reviewStatus", "")) !== -1;
      });
  }

  getCustomerFileMappingImages() {
    this.kycDetailsList = get(this.loanDetails, "kycDetailsList", []) || [];
    
    this.customerFileMappingDtoList =
      get(this.loanDetails, "customerFileMappingDTOList", []) || [];

    const customerMap = this.customerFileMappingDtoList.reduce((map, obj) => {
      map[obj.id] = obj.fileId;
      return map;
    }, {});

    const resultArray = this.kycDetailsList.map((kycDetail) => ({
      ...kycDetail,
      fileId:  kycDetail['fileId'] || customerMap[kycDetail.customerFileMappingId] ||null,
    }));

    resultArray.forEach((kycItem) =>
      this.setImageToCustomerFileMapping(kycItem)
    );
  }

  setImageToCustomerFileMapping(kycdetail: Object) {
    const purpose: string = getProperty(kycdetail, "purpose", "");
    if (purpose.toLowerCase() === "poa") {
      this.poaDocuments.push(kycdetail);
    } else if (purpose.toLowerCase() === "poi") {
      this.poiDocuments.push(kycdetail);
    }
  }

  editImage(entry, feature, key) {
    this.modalRef = this.imageEditorPopupService.open(
      <Component>ImageEditorComponent,
      entry.image,
      feature
    );
    this.modalRef.result.then((value) => {
      this.updatedDocument[entry.fileType] = value;
      entry.image = value;
    });
  }

  changeRejectReason(customerFileMappingKey, field2, field3, field4) {
    let customerFileMapping = {
      ...this.customerFileMappingDtoListMap[customerFileMappingKey],
    };
    if (field4 === "TECH_ISSUE") {
      customerFileMapping.techReviewOptionList = [];
      customerFileMapping.techReasonOptionsModel = [];
      customerFileMapping.techReviewOptionList =
        this.rejectReasonPipe.transform(
          this.kycRejectReasons,
          "KYC",
          field2,
          field3,
          field4
        );
    } else {
      customerFileMapping.reviewOptionList = [];
      customerFileMapping.reasonOptionsModel = [];
      customerFileMapping.reviewOptionList = this.rejectReasonPipe.transform(
        this.kycRejectReasons,
        "KYC",
        field2,
        field3,
        null
      );
    }
    this.customerFileMappingDtoListMap[customerFileMappingKey] =
      customerFileMapping;
  }

  kycImageSave() {
    this.disableAcceptKyc = true;
    const files = [];
    const updatedDocTypes = Object.keys(this.updatedDocument);
    Object.keys(this.customerFileMappingDtoListMap).map((key) => {
      if (this.customerFileMappingDtoListMap[key].rejectStatus)
        this.customerFileMappingDtoListMap[key].reviewStatus =
          this.customerFileMappingDtoListMap[key].rejectStatus === true
            ? "REJECT"
            : "ACCEPT";
      this.customerFileMappingDtoListMap[key].rejectReason = this
        .customerFileMappingDtoListMap[key].reasonOptionsModel
        ? this.customerFileMappingDtoListMap[key].reasonOptionsModel.toString()
        : "";
      this.customerFileMappingDtoListMap[key].techIssueReason = this
        .customerFileMappingDtoListMap[key].techReasonOptionsModel
        ? this.customerFileMappingDtoListMap[
            key
          ].techReasonOptionsModel.toString()
        : "";
      let imageBlob;
      if (
        updatedDocTypes.indexOf(
          this.customerFileMappingDtoListMap[key].fileType
        ) > -1
      ) {
        imageBlob = this.subscriptionReviewSupportService.dataURItoBlob(
          this.updatedDocument[this.customerFileMappingDtoListMap[key].fileType]
        );
      }
      if (imageBlob) {
        const imageFile = new File([imageBlob], "kyc_" + key + ".jpg", {
          type: "image/jpeg",
        });
        files.push(imageFile);
      }
    });
    if (files.length > 0) {
      this.fileService
        .uploadKaleidofinImage(
          files,
          "S3",
          this.loanDetails.customerDTO.partnerId
        )
        .subscribe((res) => {
          this.fileDTOs = res;
          if (this.fileDTOs !== undefined) {
            const updatedDocTypes = Object.keys(this.updatedDocument);
            let currentFile = 0;
            Object.keys(this.customerFileMappingDtoListMap).map((key) => {
              if (
                updatedDocTypes.indexOf(
                  this.customerFileMappingDtoListMap[key].fileType
                ) > -1
              ) {
                this.customerFileMappingDtoListMap[key].fileId =
                  this.fileDTOs[currentFile].id;
                currentFile = currentFile + 1;
              }
            });
            this.kycImageFinalSave();
          }
        });
    } else {
      this.kycImageFinalSave();
    }
  }

  kycImageFinalSave() {
    this.customerReviewService
      .saveLoanKycImageReview(
        this.loanDetails.customerDTO.id,
        this.loanDetails.loanApplicationDTO.id,
        this.customerFileMappingDtoListMap
      )
      .subscribe(() => {
        this.fetchDocReviewStatus();
      });
  }
  handleAndDisplayError(object = null, key = "") {
    let errorList = get(object, key, null);
    if (typeof errorList === "string")
      return get(object, `${key}.length`, 0) < 1;
    return errorList == null;
  }

  getGroupedKycDocuments(documents: any, key: string = "") {
    if (documents && Array.isArray(documents) && documents.length) {
      let obj = groupObjectsWithMultiParams(
        documents,
        "purpose",
        "documentType",
        "documentName"
      );
      console.log("groud", obj);
      return obj[key];
    }
    return {};
  }
}
