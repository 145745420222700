import { AddressType } from "./address-enum.model";

export class Address {
    constructor(
        public id?: number,
        public version?: number,
        public customerId?: number,
        public addressType?: AddressType,
        public address1?: any,
        public address2?: any,
        public address3?: any,
        public city?: any,
        public district?: any,
        public state?: any,
        public country?: any,
        public pincode?: number
) {
    }

}
