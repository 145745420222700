import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JhiDateUtils } from "ng-jhipster";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  KALEIDO_SERVER_API_URL,
  KREDILINE_SERVER_URL,
} from "src/app/app.constants";
import { LoanApplicationSearchSpecification } from "../models/krediline/loan-application-download.model";
import { LoanApplication } from "../models/krediline/loan-application.model";
import { LoanDetails } from "../models/krediline/loan-details.model";
import { LoanInfoDTO } from "../models/krediline/repayment/loan-info-DTO.model";
import { AssociateLender } from "../models/associate-lender.model";
import { ApplicationStatus } from "../loan/constant";
import { KcreditLoanDetailsModel } from "../loan/kcredit-loanDetails.model";
import { getProperty } from "src/app/utils/app.utils";
import {
  BULK_REJECT_URL,
  BULK_REVIEW,
  RETRY_LOAN_BOOKING
} from "src/app/shared/constants/Api.constants";
import { get } from "lodash";

@Injectable()
export class LoanReviewService {
  private resourceUrl = BULK_REVIEW;

  public loanApplication: LoanApplication;
  public loanApplications: LoanApplication[];
  public loanDetails: LoanDetails;
  public loanDetailsData: KcreditLoanDetailsModel;
  public loanInfoDTO: LoanInfoDTO;
  currentStatusStr: string;
  newStatusStr: string;
  loanReviewDedupe: boolean = false;
  loanApplicationSearchSpecification: LoanApplicationSearchSpecification;
  associateLenderList: AssociateLender[];
  constructor(private http: HttpClient, private dateUtils: JhiDateUtils) {
    this.loanApplication = new LoanApplication();
    this.loanDetails = new LoanDetails();
  }

  convertLocalDateFromServer(date: string) {
    if (date !== undefined && date != null) {
      const split = date.split("-");
      const dateObj = {
        year: parseInt(split[0]),
        month: parseInt(split[1]),
        day: parseInt(split[2]),
      };
      return dateObj;
    } else {
      return undefined;
    }
  }

  query(req?: any) {
    req.appliedFromDate = this.dateUtils.convertLocalDateToServer(
      req.search.appliedFromDate
    );
    req.appliedToDate = this.dateUtils.convertLocalDateToServer(
      req.search.appliedToDate
    );
    req.lienFromDate = this.dateUtils.convertLocalDateToServer(
      req.search.lienFromDate
    );
    req.lienToDate = this.dateUtils.convertLocalDateToServer(
      req.search.lienToDate
    );
    req.loanClosedFromDate = this.dateUtils.convertLocalDateToServer(
      req.search.loanClosedFromDate
    );
    req.loanClosedToDate = this.dateUtils.convertLocalDateToServer(
      req.search.loanClosedToDate
    );
    req.emiFromDate = this.dateUtils.convertLocalDateToServer(
      req.search.emiFromDate
    );
    req.emiToDate = this.dateUtils.convertLocalDateToServer(
      req.search.emiToDate
    );
    req.dueFromDate = this.dateUtils.convertLocalDateToServer(
      req.search.dueFromDate
    );
    req.dueToDate = this.dateUtils.convertLocalDateToServer(
      req.search.dueToDate
    );
    req.nextDueDate = this.dateUtils.convertLocalDateToServer(
      req.repaymentDate
    );
    req.applicationStatus = req.search.applicationStatus;
    let params = this.createRequestOption(req);
    return this.http
      .get(KREDILINE_SERVER_URL + this.resourceUrl, { params })
      .pipe(map((res: any) => this.convertResponse(res)));
  }

  private convertResponse(res: any): any {
    const jsonResponse = res;
    let i = 0;
    for (let element of jsonResponse) {
      jsonResponse[i].dateOfBirth = this.dateUtils.convertLocalDateFromServer(
        element.dateOfBirth
      );
      jsonResponse[i].spouseDateOfBirth =
        this.dateUtils.convertLocalDateFromServer(
          element.spouseDateOfBirth
        );
      i++;
    }
    res._body = jsonResponse;
    return res;
  }

  private createRequestOption(req?: any): HttpParams {
    let params: HttpParams = new HttpParams();
    if (req) {
      params = params.append("page", req.page);
      params = params.append("size", req.size);
      params = params.append("status", req.search.status);
      params = params.append("filterForList", req.search.filterForList);
      params = params.append(
        "lendingPartnerCode",
        req.search.lendingPartnerCode
      );
      params = params.append("appliedFromDate", req.appliedFromDate);
      params = params.append("appliedToDate", req.appliedToDate);
      params = params.append("lienFromDate", req.lienFromDate);
      params = params.append("lienToDate", req.lienToDate);
      params = params.append("loanClosedFromDate", req.loanClosedFromDate);
      params = params.append("loanClosedToDate", req.loanClosedToDate);
      params = params.append("any", req.search.selectAny);
      params = params.append("loanState", req.loanState);
      params = params.append("emiFromDate", req.emiFromDate);
      params = params.append("emiToDate", req.emiToDate);
      params = params.append("dueFromDate", req.dueFromDate);
      params = params.append("dueToDate", req.dueToDate);
      params = params.append("nextDueDate", req.nextDueDate);
      params = params.append("paymentType", req.search.paymentType);
      params = params.append("channelPartner", req.search.channelPartner);
      params = params.append("applicationStatus", req.search.applicationStatus);
      if (req.sort) {
        params = params.append("sort", req.sort);
      }
      for (const key in req.query) {
        if (req.query[key] !== "" || req.query[key] !== 0) {
          params = params.append(key, req.query[key]);
        }
      }
    }
    return params;
  }

  getLoanApplication() {
    return this.loanApplication;
  }

  getAssignee() {
    return get(this.loanApplication, "assignee", null);
  }
  create(loan: LoanDetails): Observable<LoanDetails["loan"]> {
    const copy: LoanDetails = {...loan};
    return this.http.post<LoanDetails["loan"]>(
      KREDILINE_SERVER_URL + this.resourceUrl,
      copy
    );
  }

  update(loan: LoanDetails): Observable<LoanDetails["loan"]> {
    const copy: LoanDetails = {...loan};
    return this.http.put<LoanDetails["loan"]>(
      KREDILINE_SERVER_URL + this.resourceUrl,
      copy
    );
  }
  find(id: number): Observable<LoanDetails> {
    return this.http.get<LoanDetails>(
      `${KREDILINE_SERVER_URL + this.resourceUrl}/getLoanDetails/${id}`
    );
  }

  bulkReject(
    loanApplications: any[] = [],
    reviewStatus: string = "",
    reviewReason: string = "",
    actionRequired: string = ""
  ) {
    const params = new HttpParams()
      .append("loanApplicationIds", loanApplications.join(","))
      .append("rejectStatus", reviewStatus)
      .append("remarks", reviewReason)
      .append("actionRequired", actionRequired);
    return this.http.put(BULK_REJECT_URL, JSON.stringify("{}"), {
      params,
    });
  }

  bulkStatusUpdate(
    loanApplications: any[] = [],
    reviewStatus: string = "",
    rejectCondition: string = "",
    actionRequired: string[] = [],
    isReferred: boolean = false
  ): Observable<any> {
    const key = actionRequired ? actionRequired.toString() : '';
    const params = new HttpParams()
      .append("loanApplicationIds", loanApplications.join(","))
      .append(
        "reviewStatus",
        reviewStatus === ApplicationStatus.forceReject
          ? ApplicationStatus.reject
          : reviewStatus
      )
      .append("rejectCondition", rejectCondition)
      .append(
        "actionRequired",
        reviewStatus === "reject"
          ? null
          : key
      )
      // Optional Parameter to force Reject
      .append(
        "forcedApplicationReject",
        reviewStatus === ApplicationStatus.forceReject
      )
      .append("isReferred", isReferred);
    return this.http.post(
      KREDILINE_SERVER_URL + this.resourceUrl,
      JSON.stringify("{}"),
      {
        params,
      }
    );
  }

  checkKiScoreApproveOverrule(loanApplicationId: any): Observable<any> {
    return this.http.get(
      `${KREDILINE_SERVER_URL}/api/backoffice/loanReview/checkKiScoreOverrule/` +
        loanApplicationId,
      { observe: "body" }
    );
  }

  loanApplicationsWithSubscriptionNumber(
    customerSubscriptionNumber: any
  ): Observable<LoanApplication[]> {
    return this.http.get<LoanApplication[]>(
      `${KREDILINE_SERVER_URL + this.resourceUrl}/getLoanNoBySubId/` +
        customerSubscriptionNumber
    );
  }

  schedulePayment(
    loanApplication: number,
    amount1: any,
    date: any
  ): Observable<any> {
    date = this.dateUtils.convertLocalDateToServer(date);
    return this.http.get(
      `${KALEIDO_SERVER_API_URL}api/backoffice/demand/processOpsInitiatedDemands`,
      {
        params: {
          applicationNumber: loanApplication,
          amount: amount1,
          repaymentDate: date,
        },
      }
    );
  }

  postFile(fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(
      `${KALEIDO_SERVER_API_URL}api/backoffice/upload/bulkLoanPaymentStatus`,
      formData
    );
  }

  postFileToCreateLoan(fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(
      `${KREDILINE_SERVER_URL}api/backoffice/loans/applications/uploadNgoCustomerForLoan`,
      formData
    );
  }

  findLoanIfo(applicationNumber: number): Observable<LoanInfoDTO> {
    return this.http.get<LoanInfoDTO>(
      `${KREDILINE_SERVER_URL}api/backoffice/loans/applications/findLoanInfo`,
      {
        params: {
          loanApplicationId: applicationNumber,
        },
      }
    );
  }

  getLoanDemandViewDetail(id: number): Observable<LoanApplication> {
    return this.http.get<LoanApplication>(
      `${KREDILINE_SERVER_URL + this.resourceUrl}/getRepaymentInfo/`,
      {
        params: {
          loanApplicationId: id,
        },
      }
    );
  }

  amendRepaymentDate(
    id: number,
    repaymentDate: Date,
    loanTenure: number
  ): Observable<LoanApplication> {
    return this.http.get<LoanApplication>(
      `${KREDILINE_SERVER_URL + this.resourceUrl}/amendRepaymentDateAndTenure`,
      {
        params: {
          loanApplicationId: id,
          repaymentDate: repaymentDate.toString(),
          tenure: loanTenure,
        },
      }
    );
  }

  validateFile(name: string) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "xls") {
      return true;
    } else if (ext.toLowerCase() == "csv") {
      return true;
    } else {
      return false;
    }
  }

  validateFileForNgoUpload(name: string) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "xls") {
      return true;
    } else if (ext.toLowerCase() == "xlsx") {
      return true;
    } else {
      return false;
    }
  }

  downloadLienRelease(id: any): Observable<any> {
    const out = { data: null, filename: null };
    return this.http
      .get(
        `${
          KREDILINE_SERVER_URL + this.resourceUrl
        }/downloadLienReleaseLetter/${id}`,
        {
          observe: "response",
          responseType: "blob",
        }
      )
      .pipe(
        map((res: HttpResponse<Blob>) => {
          out.data = new Blob([res.body]);
          out.filename = res.headers.get("x-file-name");
          return out;
        })
      );
  }

  downloadLienRequest(id: any): Observable<any> {
    const out = { data: null, filename: null };
    return this.http
      .get(
        `${
          KREDILINE_SERVER_URL + this.resourceUrl
        }/downloadLienRequestDocument/${id}`,
        {
          observe: "response",
          responseType: "blob",
        }
      )
      .pipe(
        map((res: HttpResponse<Blob>) => {
          out.data = new Blob([res.body]);
          out.filename = res.headers.get("x-file-name");
          return out;
        })
      );
  }

  downloadLenderLetter(id: any): Observable<any> {
    const out = { data: null, filename: null };
    return this.http
      .get(
        `${KREDILINE_SERVER_URL + this.resourceUrl}/downloadLenderForm/${id}`,
        {
          observe: "response",
          responseType: "blob",
        }
      )
      .pipe(
        map((res: HttpResponse<Blob>) => {
          out.data = new Blob([res.body]);
          out.filename = res.headers.get("x-file-name");
          return out;
        })
      );
  }

  processPhysicalLien(id: number): Observable<LoanApplication> {
    return this.http.post<LoanApplication>(
      `${KREDILINE_SERVER_URL + this.resourceUrl}/physicalLienReceived/${id}`,
      ""
    );
  }

  setLoanDetailsData(loanDetails: KcreditLoanDetailsModel = null) {
    this.loanDetailsData = loanDetails;
  }

  getApplicantName(): string {
    return getProperty(this.loanDetailsData, "customerDTO.name", null);
  }

  getLoanStatus(): string {
    return getProperty(
      this.loanDetailsData,
      "loanApplicationDTO.applicationStatus",
      ""
    );
  }

  retryLoanBooking(loanId: number): Observable<any> {
    const params = new HttpParams().append("loanApplicationId", loanId);
    return this.http.post(RETRY_LOAN_BOOKING, null, {
      params,
    });
  }
}
