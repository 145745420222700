import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FormControl, ValidationErrors } from "@angular/forms";
import { get, set } from "lodash";
import { debounceTime } from "rxjs/operators";
import { getProperty } from "src/app/utils/app.utils";

@Component({
  selector: "app-repayment-table-input-field",
  templateUrl: "./repayment-table-input-field.component.html",
})
export class RepaymentTableInputFieldComponent implements OnInit, OnChanges {
  @Input() valueObj: any = {};
  @Input() key: string = "";
  @Input() finalLoanAmount: number = null;
  @Input() disableEdit: boolean = false;
  @Output() saveDetails: EventEmitter<any> = new EventEmitter<any>();
  
  interestAmount: number = null;
  inputFormControl: FormControl = new FormControl();

  ngOnInit(): void {
    this.inputFormControl = new FormControl(this.valueObj?.[this.key] ?? "", [
      (control: FormControl) => this.checkMinValue(control),
      (control: FormControl) => this.checkMaxValue(control),
    ]);
    this.subscribeToFormChange();
    this.toggleFormControlState();
    this.interestAmount = getProperty(this.valueObj, "interestAmount", null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disableEditChange: SimpleChange = get(changes, "disableEdit", null);
    if (disableEditChange && !disableEditChange.isFirstChange()) {
      this.toggleFormControlState();
    }
  }

  subscribeToFormChange(): void {
    this.inputFormControl.valueChanges
      .pipe(debounceTime(2000))
      .subscribe(() => {
        if (!this.inputFormControl.errors) {
          this.saveDetails.emit();
        }
      });
  }

  toggleFormControlState(): void { 
    if (this.disableEdit) {
      this.inputFormControl.disable({emitEvent: false});
    } else {
      this.inputFormControl.enable({emitEvent: false});
    }
  }

  checkMinValue(control: FormControl): ValidationErrors | null {
    const value: number = control.value;
    if (value && this.interestAmount && value < this.interestAmount) {
      return {
        min: true,
      };
    }
    return null;
  }

  checkMaxValue(control: FormControl): ValidationErrors | null {
    const value: number = control.value;
    if (value && this.finalLoanAmount && value > this.finalLoanAmount) {
      return {
        max: true,
      };
    }
    return null;
  }

  checkInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let regex = /^\d*(\.\d{0,2})?$/;
    if (!regex.test(input.value)) {
      input.value = input.value.slice(0, input.value.length - 1);
      this.inputFormControl.setValue(input.value, { emitEvent: false });
    }
    const currentValue: number = this.inputFormControl.value;
    if (!this.inputFormControl.errors) {
      set(this.valueObj, this.key, currentValue);
    }
  }
}
