<div class="col-12 row no-space">
  <div class="col-xs-1">
    <div *ngIf="tableData" class="col-md-12">
      <div *ngIf="tableData.length > 0; else noloans">
        <table
          class="table table-light col-md-12 table-bordered table-list-size"
        >
        <caption></caption>
          <thead class="thead-inverse">
            <tr>
              <th><span>Date</span></th>
              <th><span>File name</span></th>
              <th><span>User name</span></th>
              <th><span>Report type</span></th>
              <th><span>Date range</span></th>
              <th><span>Partner</span></th>
              <th><span>Product type</span></th>
              <th><span>Status</span></th>
              <th><span>Action</span></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let reportData of tableData">
              <td class="tooltip-cell">
                {{ dataUtils.getDate(reportData) | customDate }}
              </td>
              <td
                class="tooltip-cell"
                [title]="dataUtils.getFileName(reportData)"
              >
                {{ dataUtils.getFileName(reportData) }}
              </td>
              <td
                class="tooltip-cell"
                [title]="dataUtils.getUserName(reportData)"
              >
                {{ dataUtils.getUserName(reportData) }}
              </td>
              <td class="tooltip-cell" [title]="getReportType(reportData)">
                {{ getReportType(reportData) }}
              </td>
              <td
                class="tooltip-cell"
                [title]="dataUtils.getDateRange(reportData)"
              >
                {{ dataUtils.getDateRange(reportData) }}
              </td>
              <td
                class="tooltip-cell"
                [title]="dataUtils.getPartner(reportData, partners)"
              >
                {{ dataUtils.getPartner(reportData, partners) }}
              </td>
              <td
                class="tooltip-cell"
                [title]="dataUtils.getProductType(reportData)"
              >
                {{ dataUtils.getProductType(reportData) }}
              </td>
              <td title="{{ dataUtils.getReportRemarks(uploadReport) }}">
                {{ dataUtils.getReportStatus(reportData) }}
              </td>
              <td>
                <div class="col-md-3 button-group">
                  <button
                    mat-icon-button
                    title="Downlaod All Files"
                    class="kcredit-primary-color"
                    [disabled]="dataUtils.validateFileId(reportData)"
                    (click)="dataUtils.downLoadFile(reportData)"
                  >
                    <span class="material-icons">download_for_offline</span>
                  </button>
                  <button
                    mat-icon-button
                    title="Replay"
                    class="kcredit-primary-color"
                    [disabled]="dataUtils.validateFileId(reportData)"
                    (click)="dataUtils.replayReportProcessing(reportData)"
                  >
                    <span class="material-icons">refresh</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="bg-light">
              <td colspan="12">
                <div class="row pagination-align">
                  <jhi-item-count
                    [page]="page"
                    [total]="totalItems"
                    [itemsPerPage]="itemsPerPage"
                  >
                  </jhi-item-count>
                </div>
                <div class="row pagination-align">
                  <ngb-pagination
                    class="text-center-div"
                    [collectionSize]="totalItems"
                    [(page)]="page"
                    [pageSize]="itemsPerPage"
                    (pageChange)="loadDataByPage(page)"
                    [maxSize]="3"
                    [rotate]="true"
                    [boundaryLinks]="true"
                  >
                  </ngb-pagination>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <ng-template #noloans>
        <hr />
        <h3 class="text-center kcredit-failure-color">No Loans found</h3>
      </ng-template>
    </div>
  </div>
</div>
