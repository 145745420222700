import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { ActivatedRoute } from "@angular/router";
import { JhiLanguageService } from "ng-jhipster";
import { PrincipalService } from "src/app/core";
import { KCREDIT_ITEMS_PER_PAGE } from "src/app/shared";
import { DashboardService } from "../../dashboard/dashboard.service";
import { LoanApplicationService } from "../services/krediline/loan-application.service";
import { LoanPhaseMetrics } from "../models/loan-overview/loan-application-phase-metrics";
import { getProperty } from "src/app/utils/app.utils";
import { UiConfigService } from "../services/ui-config.service";
import { UI_COMPONENTS } from "src/app/constants/ui-config";
import { FILTERS } from "../services/loan-application-search-filter.service";

@Component({
  selector: "jhi-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["../kaleido-credit.css", "./overview.component.scss"],
})
export class OverviewComponent implements OnInit, OnDestroy {
  currentAccount: any;
  errorObject: any;
  success: any;
  routeData: any;
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  status: any = "";
  predicate: any;
  previousPage: any;
  reverse: any;
  loanDetails: any;
  languageKey;
  loanCounts: Map<any, Map<any, any>>;

  requiredFilters: Array<any> = new Array();
  tableData: LoanPhaseMetrics[];
  tableTotal: LoanPhaseMetrics;
  searchBy: any = {};

  constructor(
    private jhiLanguageService: JhiLanguageService,
    private principal: PrincipalService,
    private activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private loanApplicationService: LoanApplicationService,
    private dashboardService: DashboardService,
    private uiConfigService: UiConfigService
  ) {
    this.itemsPerPage = KCREDIT_ITEMS_PER_PAGE;
  }

  ngOnInit() {
    this.routeData = this.activatedRoute.data.subscribe((data) => { });
    this.principal.identity().then((account) => {
      this.dashboardService.sendMessage("shownav");
      this.currentAccount = account;
      this.languageKey = this.currentAccount.langKey;
      this.jhiLanguageService.changeLanguage(this.languageKey);
      this.getLoanMetrics();
      this.getUiConfiguration();
    });
  }
  ngOnDestroy() {
    this.routeData.unsubscribe();
  }

  getLoanMetrics() {
    this.loanApplicationService.getPartnerLoanCounts(this.searchBy).subscribe((response: Map<string, LoanPhaseMetrics>) => {
      this.tableData = [];
      this.tableTotal = new LoanPhaseMetrics({});
      response.forEach((countDto, partnerName) => {
        countDto.partnerName = partnerName;
        this.tableData.push(countDto);
        this.tableTotal.addMoreCount(countDto);
      });
    });
  }

  getUiConfiguration(): void {
    this.uiConfigService
      .getUiConfigurations()
      .subscribe((configurations: Object) => {
        const overviewSection: Object = getProperty(
          configurations,
          UI_COMPONENTS.LOAN_OVERVIEW_REVIEW,
          {}
        );

        const filters: Array<string> = getProperty(
          overviewSection,
          "requiredFilters",
          []
        );
        this.requiredFilters = filters.map((filter) => FILTERS[filter]);
      });
  }

  onFilterChange(filterValues: Array<any> = []): void {
    this.updateFilter(filterValues);
    this.getLoanMetrics();
  }

  updateFilter(filters: Array<any> = []) {
    if (filters) {
      filters.forEach((filter) => {
        this.searchBy[getProperty(filter, "type", "")] = getProperty(
          filter,
          "value",
          ""
        );
      });
    }
  }

}
